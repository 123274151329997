import { useState, useEffect } from "react"

import useFetch from "shared/model/useFetch"

const useContentList = (isObserver) => {

    const [backendState, setBackendState] = useFetch()
    const [allData, setData] = useState([])   // Backend Data 후처리
    const [lastIndex, setLastIndex] = useState(0)

    useEffect(() => {
        if (isObserver) setBackendState("GET", `/contents?contentId=${lastIndex}`)
    }, [isObserver])

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                const dataList = backendState?.data
                if (dataList && dataList.length > 0) {  // 추가로 받아와진 리스트가 있다면
                    setData([...allData, ...dataList])
                    setLastIndex(dataList[dataList.length - 1].contentId)
                }
                break
        }
    }, [backendState])

    return [allData]
}

export default useContentList