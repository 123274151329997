import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import useFetch from "shared/model/useFetch"
import useTextModalAtom from "shared/model/useTextModalAtom"
import regx from "shared/constant/regx"

const useUpdateMyInfo = (pwRef, pwConfirmRef, nameRef, contactRef, emailRef, isContactChange, isContactConfirm) => {
    const navigate = useNavigate()
    const [backendState, setBackendState] = useFetch()
    const [, setModal] = useTextModalAtom()

    const exception = (pw, pwConfirm, name, contact, email) => {
        if (!pw.match(regx.password)) {
            setModal("비밀번호 값을 확인해주세요.")
            return false
        }
        if (pw !== pwConfirm) {
            setModal("비밀번호가 일치하는지 확인해주세요.")
            return false
        }
        if (!name.match(regx.max20)) {
            setModal("이름 값을 확인해주세요.")
            return false
        }
        if (!contact.match(regx.contact)) {
            setModal("연락처 값을 확인해주세요.")
            return false
        }
        if (!email.match(regx.email)) {
            setModal("이메일 값을 확인해주세요.")
            return false
        }

        // 연락처가 수정되었을 때, 연락처 인증 여부
        if (isContactChange && !isContactConfirm) {
            setModal("모든 인증을 진행해주세요.")
            return false
        }

        return true
    }

    const clickEvent = () => {
        const pw = pwRef?.current?.value
        const pwConfirm = pwConfirmRef?.current?.value
        const name = nameRef?.current?.value
        const contact = contactRef?.current?.value
        const email = emailRef?.current?.value

        if (exception(pw, pwConfirm, name, contact, email)) {
            setBackendState("POST", `/users/information/edit`, {
                "password": pw,
                "name": name,
                "phone": contact,
                "email": email
            })
        }
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                navigate("/mypage")
                break
        }
    }, [backendState])

    return [clickEvent]
}

export default useUpdateMyInfo