import { useRef } from "react"

import Style from "./style"
import useKakaoEvent from "./model/useKakaoEvent"
import useCustomerTypeEvent from "./model/useCustomerTypeEvent"
import useMatchingRequest from "../../feature/matching/usePostMatching"
import background from "./assets/background.svg"
import check from "./assets/check.svg"
import uncheck from "./assets/uncheck.svg"
import alarm from "./assets/alarm.svg"
import alarmOn from "./assets/alarm_on.svg"

import useRegxCheck from "shared/model/useRegxCheck"
import LabelInput from "shared/ui/LabelInput"
import LabelDoubleInput from "shared/ui/LabelDoubleInput"
import LabelDropdownInput from "shared/ui/LabelDropdownInput"
import Button from "shared/ui/Button"
import regx from "shared/constant/regx"

const Matching = () => {
    const addressList = ["서울 강북", "서울 강남", "경기 북부", "경기 남부", "강원도", "충청남도", "충청북도", "경상북도", "경상남도", "전라북도", "전라남도", "인천광역시", "대전광역시", "세종특별자치시", "대구광역시", "울산광역시", "부산광역시", "광주광역시", "제주도", "해외"]
    const sectorList = ["한식", "중식", "일식", "양식", "아시안푸드", "퓨전음식", "커피전문점", "디저트카페", "베이커리카페", "디저트", "판매점", "오락스포츠", "기타"]
    const customerTypeList = [{
        "label": "개인 고객",
        "key": "ROLE_CUSTOMER"
    },
    {
        "label": "브랜드 대표",
        "key": "ROLE_BRAND"
    }]

    const addressRef = useRef()
    const sectorRef = useRef()
    const minCostRef = useRef()
    const maxCostRef = useRef()
    const minFloorRef = useRef()
    const minFloorTypeRef = useRef()
    const maxFloorRef = useRef()
    const maxFloorTypeRef = useRef()
    const minAreaRef = useRef()
    const maxAreaRef = useRef()

    const [isMinCostNormal, checkMinCostEvent] = useRegxCheck(minCostRef, regx.number)
    const [isMaxCostNormal, checkMaxCostEvent] = useRegxCheck(maxCostRef, regx.number)
    const [isMinFloorNormal, checkMinFloorEvent] = useRegxCheck(minFloorRef, regx.number)
    const [isMaxFloorNormal, checkMaxFloorEvent] = useRegxCheck(maxFloorRef, regx.number)
    const [isMinAreaNormal, checkMinAreaEvent] = useRegxCheck(minAreaRef, regx.double)
    const [isMaxAreaNormal, checkMaxAreaEvent] = useRegxCheck(maxAreaRef, regx.double)

    const [customerType, customerTypeEvent] = useCustomerTypeEvent(customerTypeList)
    const [kakaoAgree, kakaoAgreeEvent] = useKakaoEvent()

    const [postMatchingEvent] = useMatchingRequest(
        addressRef, sectorRef, minCostRef, maxCostRef, minFloorRef, minFloorTypeRef, maxFloorRef, maxFloorTypeRef, minAreaRef, maxAreaRef,
        customerType.key, kakaoAgree
    )

    return (
        <Style.WrapperDiv>
            <Style.Form>
                <Style.Title>내가 찾고있던 매물이 <span>커밍!<br />매칭</span>을 시작해보세요</Style.Title>
                <Style.Explain>
                    고객님의 상황에 딱 맞는 매물을 추천해드립니다.<br />
                    예산은 보증금, 권리금 등을 포함하여 적어주세요.
                </Style.Explain>

                <Style.InputDiv>
                    <LabelInput value={"지역"} type="select" inputRef={addressRef} optionList={addressList} required />
                    <LabelInput value={"업종"} type="select" inputRef={sectorRef} optionList={sectorList} required />
                    <LabelDoubleInput value={"예산"} unit={"만원"} inputRefList={[minCostRef, maxCostRef]} required
                        blurEvent={[checkMinCostEvent, checkMaxCostEvent]} isNormal={[isMinCostNormal, isMaxCostNormal]} exceptionMsg={"0 이상의 숫자를 입력해주세요."} />
                    <LabelDropdownInput value={"층"} unit={"층"} inputRefList={[minFloorRef, maxFloorRef]} required
                        selectRefList={[minFloorTypeRef, maxFloorTypeRef]} optionList={["지상", "지하"]}
                        blurEvent={[checkMinFloorEvent, checkMaxFloorEvent]} isNormal={[isMinFloorNormal, isMaxFloorNormal]} exceptionMsg={"숫자를 입력해주세요."} />
                    <LabelDoubleInput value={"면적"} unit={"m²"} inputRefList={[minAreaRef, maxAreaRef]} required
                        blurEvent={[checkMinAreaEvent, checkMaxAreaEvent]} isNormal={[isMinAreaNormal, isMaxAreaNormal]} exceptionMsg={"0 이상의 숫자를 입력해주세요."} />
                </Style.InputDiv>

                <Style.SubmitDiv>

                    <Style.RadioDiv>
                        {customerTypeList?.map(elem =>
                            <Style.RadioElem onClick={() => customerTypeEvent(elem)}>
                                <img src={customerType.label === elem.label ? check : uncheck} />
                                <Style.RadioLabel $on={customerType.label === elem.label}>{elem.label}</Style.RadioLabel>
                            </Style.RadioElem>
                        )}
                    </Style.RadioDiv>

                    <Style.ButtonDiv>
                        <Button clickEvent={postMatchingEvent}>MATCHING</Button>
                    </Style.ButtonDiv>

                    <Style.RadioElem onClick={kakaoAgreeEvent}>
                        <img src={kakaoAgree ? alarmOn : alarm} />
                        <Style.RadioLabel $on={kakaoAgree}>카카오 알림톡 수신 설정</Style.RadioLabel>
                    </Style.RadioElem>

                </Style.SubmitDiv>

            </Style.Form>
            <Style.BackgroundImg src={background} />
        </Style.WrapperDiv>
    )
}

export default Matching