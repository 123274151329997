import styled from "styled-components"

export default {
    "WrapperDiv": styled.main`
        width: 90%;
        max-width: 616px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: clamp(48px, 20vw, 160px) 0;
        padding: clamp(40px, 10vw, 80px) 96px;
        background-color: ${({ theme }) => theme.color.white};
        border-radius: 16px;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                padding-left: 5%;
                padding-right: 5%;
            }
        }
    `,
    "Title": styled.h2`
        ${({ theme }) => theme.font.large_bold};
    `,
    "Form": styled.div`
        display: flex;
        flex-direction: column;
        margin-top: clamp(20px, 5vw, 40px);
        gap: clamp(16px, 3vw, 24px);
    `,
    "ButtonDiv": styled.div`
        margin-top: clamp(32px, 8.5vw, 68px);
    `,
    "Hr": styled.hr`
        width: 100%;
        height: 0.4px;
        background-color: ${({ theme }) => theme.color.black};
        border: none;
        margin: 48px 0 32px;
    `,
    "ResultTile": styled.h2`
        ${({ theme }) => theme.font.small_bold};
    `,
    "ResultValue": styled.p`
        ${({ theme }) => theme.font.medium_bold};
        color: ${({ theme }) => theme.color.major};
        margin-top: 16px;
    `
}