import { useRef } from "react"
import { useNavigate } from "react-router-dom"

import Style from "./style"
import useLoginRequest from "../../feature/account/usePostLogin"

import InputLabel from "shared/ui/LabelInput"
import Button from "shared/ui/Button"
import useRegxCheck from "shared/model/useRegxCheck"
import regx from "shared/constant/regx"

const Login = () => {
    const navigate = useNavigate()
    const idRef = useRef()
    const pwRef = useRef()

    const [loginEvent, loginEnterEvent] = useLoginRequest(idRef, pwRef)
    const [isIdNormal, checkIdEvent] = useRegxCheck(idRef, regx.max20)
    const [isPwNormal, checkPwEvent] = useRegxCheck(pwRef, regx.password)

    return (
        <Style.WrapperDiv onKeyUp={loginEnterEvent}>
            <Style.Title>로그인</Style.Title>
            <Style.Form>
                <InputLabel value={"아이디"} placeholder={"아이디를 입력하세요"} inputRef={idRef} blurEvent={checkIdEvent} isNormal={isIdNormal} exceptionMsg={"20자 이하의 값을 입력하세요."} required />
                <InputLabel value={"비밀번호"} type={"password"} placeholder={"비밀번호를 입력하세요"} inputRef={pwRef} blurEvent={checkPwEvent} isNormal={isPwNormal} exceptionMsg={"20자 이하, 영문과 숫자와 특수문자를 포함해 입력해주세요."} required />
            </Style.Form>
            <Style.ButtonDiv>
                <Button clickEvent={loginEvent}>로그인</Button>
                <Style.signupBtn onClick={() => navigate("/signup-pre")}>아직 회원이 아니신가요?</Style.signupBtn>
            </Style.ButtonDiv>
            <Style.ToolDiv>
                <Style.ToolBtn onClick={() => navigate("/id-find")}>아이디 찾기</Style.ToolBtn>
                <Style.Hr />
                <Style.ToolBtn onClick={() => navigate("/pw-find")}>비밀번호 찾기</Style.ToolBtn>
            </Style.ToolDiv>
        </Style.WrapperDiv>
    )
}

export default Login