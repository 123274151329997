import { useNavigate } from "react-router-dom"
import { useInView } from "react-intersection-observer"

import Style from "./style"
import useGetContentRequest from "../../../../feature/content/useContentList"
import convertTimestamp from "shared/lib/convertTimestamp"

const Login = () => {
    const navigate = useNavigate()

    const [observerRef, isObserver] = useInView()
    const [contentList] = useGetContentRequest(isObserver)

    return (
        <Style.WrapperDiv>
            {contentList?.map(elem =>
                <Style.Article onClick={() => navigate(`/content-detail/${elem?.contentId}`)}>
                    <Style.Thumbnail src={elem?.mainImageUrl} />
                    <Style.Text>{elem?.author}</Style.Text>
                    <Style.Title>{elem?.title}</Style.Title>
                    <Style.Content>{elem?.paragraph}</Style.Content>
                    <Style.Text>{convertTimestamp(elem?.createdAt, "date")}</Style.Text>
                </Style.Article>
            )}

            {/* Intersection Observer를 위한 Tag */}
            <div ref={observerRef} />
        </Style.WrapperDiv>
    )
}

export default Login