import Style from "./style"
import caution500 from "./asset/caution500.svg"

const InternalServerError = () => {

    return (
        <Style.WrapperDiv>
            <Style.CautionSvg src={caution500} />
            <Style.Title>죄송합니다.</Style.Title>
            <Style.Content>서버와의 통신 중에 문제가 발생하였습니다.</Style.Content>
        </Style.WrapperDiv>
    )
}

export default InternalServerError