import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export default {
    "WrapperDiv": styled.section`
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    `,

    // ===== Slide Parent =====

    "Slider": styled(Slider)`
    `,
    "ImgDiv": styled.div`
        position: relative;
        cursor: pointer;
    `,

    // ===== Slide Element =====

    "Overlay": styled.div`
        position: absolute;
        width: calc(100% - 40px);
        margin-left: 20px;
        height: 100%;
        background: linear-gradient(
            rgba(0, 0, 0, 0) 0%, 
            rgba(0, 0, 0, 0.9) 100%
        );
        border-radius: 20px;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                width: calc(100% - 10px);
                margin-left: 5px;
                border-radius: 8px;
            }
        }
    `,
    "Img": styled.img`
        width: 100%;
        width: calc(100% - 40px);
        margin-left: 20px;
        height: 360px;
        border-radius: 20px;
        object-fit: cover;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                width: calc(100% - 10px);
                margin-left: 5px;
                border-radius: 8px;
                height: clamp(180px, 46vw, 360px);
            }
        }
    `,

    "TextBox": styled.div`
        position: absolute;
        width: 100%;
        padding-left: 8%;
        padding-right: 8%;
        top: 40%;
    `,
    "New": styled.h1`
        color: ${({ theme }) => theme.color.white};
        ${({ theme }) => theme.font.large_bold};

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                ${({ theme }) => theme.font.medium_bold};
            }
        }
    `,
    "Title": styled.h1`
        width: 100%;
        color: ${({ theme }) => theme.color.white};
        ${({ theme }) => theme.font.extra_large_bold};
        margin-top: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                ${({ theme }) => theme.font.large_bold};
            }
        }
    `,
    "Date": styled.p`
        color: ${({ theme }) => theme.color.white};
        ${({ theme }) => theme.font.medium};
        margin-top: 16px;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                ${({ theme }) => theme.font.small};
            }
        }
    `
}