import { Navigate, Outlet } from "react-router-dom"

import useAuthCookie from "shared/model/useAuthCookie"

export const PublicRoute = () => {
    const [authCookie] = useAuthCookie()

    return !authCookie
        ? <Outlet />
        : <Navigate to="/" />
}

export const PrivateRoute = () => {
    const [authCookie] = useAuthCookie()

    return authCookie
        ? <Outlet />
        : <Navigate to="/login" />
}