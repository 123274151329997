import styled from "styled-components"

export default {
    "WrapperDiv": styled.main`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        max-width: 1200px;
        margin: clamp(40px, 15vw, 120px) 0 clamp(40px, 20vw, 160px) 0;
    `,
    "Title": styled.h2`
        width: 100%;
        ${({ theme }) => theme.font.large_bold};
    `,
    "Content": styled.p`
        width: 100%;
        ${({ theme }) => theme.font.small};
        background-color: ${({ theme }) => theme.color.white};
        border-radius: 12px;
        padding: clamp(40px, 10vw, 80px) 96px;
        margin-top: 32px;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                padding-left: 5%;
                padding-right: 5%;
            }
        }
    `,
    "ButtonDiv": styled.div`
        width: 320px;
        margin-top: 64px;
    `
}