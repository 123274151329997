import styled, { css } from "styled-components"

export default {
    "BgDiv": styled.div`
        position: fixed;
        top: 0px;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        min-height: 100vh;
        background-color: ${({ theme }) => theme.color.opacity};
        z-index: 2;
    `,
    "ModalDiv": styled.div`
        min-width: 320px;
        width: fit-content;
    `,
    "Label": styled.p`
        width: 100%;
        padding: 72px 32px;
        ${({ theme }) => theme.font.small_bold};
        text-align: center;
        background-color: ${({ theme }) => theme.color.white};
        border-radius: 12px 12px 0px 0px;
    `,
    "BtnDiv": styled.div`
        display: flex;
    `,
    "Btn": styled.button`
        flex-grow: 1;
        padding: 12px 0px;
        ${({ theme }) => theme.font.small_bold};
        color: ${({ theme }) => theme.color.white};

        ${({ left }) => left && css`
            background-color: ${({ theme }) => theme.color.major};
            border-radius: 0px 0px 0px 12px;
        `}
        ${({ right }) => right && css`
            background-color: ${({ theme }) => theme.color.dark_grey};
            border-radius: 0px 0px 12px 0px;
        `}
    `
}
