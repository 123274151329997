import { useRef } from "react"
import { useParams } from "react-router-dom"

import Style from "./style"
import usePostConsultingRequest from "../../feature/consulting/usePostConsulting"

import InputLabel from "shared/ui/LabelInput"
import Button from "shared/ui/Button"
import useRegxCheck from "shared/model/useRegxCheck"
import regx from "shared/constant/regx"

const Consulting = () => {
    const params = useParams()
    const propertyIdx = params.propertyIdx

    const dateRef = useRef()
    const timeRef = useRef()

    const [postConsultingEvent] = usePostConsultingRequest(propertyIdx, dateRef, timeRef)
    const [isDateNormal, checkDateEvent] = useRegxCheck(dateRef, regx.date)
    const [isTimeNormal, checkTimeEvent] = useRegxCheck(timeRef, regx.time)

    return (
        <Style.WrapperDiv>
            <Style.Title>상담신청</Style.Title>
            <Style.Form>
                <InputLabel value={"희망 상담 일자"} placeholder={"ex) 2024-01-01"} inputRef={dateRef} blurEvent={checkDateEvent} isNormal={isDateNormal} exceptionMsg={"올바른 날짜 형태로 입력해주세요. ( yyyy-mm-dd )"} required />
                <InputLabel value={"희망 상담 시간"} placeholder={"ex) 09:30"} inputRef={timeRef} blurEvent={checkTimeEvent} isNormal={isTimeNormal} exceptionMsg={"올바른 시간 형태로 입력해주세요. ( hh:mm )"} required />
                <Style.ButtonDiv>
                    <Button clickEvent={postConsultingEvent}>상담 신청</Button>
                </Style.ButtonDiv>
            </Style.Form>
        </Style.WrapperDiv>
    )
}

export default Consulting