import React from "react";

import Style from "./style"

import useTextModalAtom from "shared/model/useTextModalAtom";

const TextModal = () => {

    const [label, , resetState] = useTextModalAtom()

    return (
        <Style.BgDiv>
            <Style.ModalDiv>
                <Style.Label>{label}</Style.Label>
                <Style.Btn onClick={resetState}>확인</Style.Btn>
            </Style.ModalDiv>
        </Style.BgDiv>
    )
}

export default TextModal