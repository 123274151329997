import { useNavigate } from "react-router-dom"

import Style from "./style"
import useBannerListRequest from "../../../../feature/banner/useBannerList"
import convertTimestamp from "shared/lib/convertTimestamp"

const Banner = () => {
    const navigate = useNavigate()

    const [bannerListState] = useBannerListRequest()

    const slickSetting = {
        "dots": false,
        "arrows": false,
        "centerMode": true,
        "centerPadding": "10%",
        "infinite": true,
        "pauseOnHover": true,
        "pauseOnFocus": true,
        "autoplay": true,
        "speed": 650,
        "autoplaySpeed": 3000,
        "slidesToShow": 1,
        "slidesToScroll": 1,
    }

    return (
        <Style.WrapperDiv>
            <Style.Slider {...slickSetting}>
                {bannerListState?.map(elem =>
                    <Style.ImgDiv onClick={() => navigate(`/content-detail/${elem?.contentId}`)}>
                        <Style.Overlay />
                        <Style.Img src={elem?.mainImageUrl} />
                        <Style.TextBox>
                            <Style.New>NEW ↗</Style.New>
                            <Style.Title>{elem?.title}</Style.Title>
                            <Style.Date>{convertTimestamp(elem?.createdAt, "date")}</Style.Date>
                        </Style.TextBox>
                    </Style.ImgDiv>
                )}
            </Style.Slider>
        </Style.WrapperDiv>
    )
}

export default Banner