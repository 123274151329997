import { useNavigate } from "react-router-dom"

import Style from "./style"
import useMyInfoRequest from "../../feature/account/useMyInfo"
import useMatchingInfoRequest from "feature/matching/useMatchingInfo"
import useWithdrawRequest from "../../feature/account/useDeleteWithdraw"

import Button from "shared/ui/Button"
import useConfirmModalAtom from "shared/model/useConfirmModalAtom"

const Mypage = () => {
    const navigate = useNavigate()
    const [, , setConfirmModal] = useConfirmModalAtom()

    const [myInfo] = useMyInfoRequest()
    const [matchingInfo] = useMatchingInfoRequest()
    const [withdrawEvent] = useWithdrawRequest()

    return (
        <Style.WrapperDiv>

            <Style.AreaDiv>

                <Style.Title>내 정보</Style.Title>

                <Style.Table>
                    {myInfo?.map(elem =>
                        <Style.Elem>
                            <Style.ElemLabelDiv>
                                <Style.ElemLabel>{elem?.label}</Style.ElemLabel>
                            </Style.ElemLabelDiv>
                            <Style.ElemValueDiv>
                                <Style.ElemValue $type={elem?.type}>{elem?.value}</Style.ElemValue>
                            </Style.ElemValueDiv>
                        </Style.Elem>
                    )}
                </Style.Table>

                <Style.ButtonDiv>
                    <Style.ButtonArea>
                        <Button clickEvent={() => navigate("/mypage-edit")}>수정</Button>
                    </Style.ButtonArea>
                    <Style.ButtonArea>
                        <Button clickEvent={() => setConfirmModal("매칭 정보 등 모든 기록이 삭제됩니다.\n정말 탈퇴하시겠습니까?", () => withdrawEvent)}>탈퇴</Button>
                    </Style.ButtonArea>
                </Style.ButtonDiv>

            </Style.AreaDiv>

            {matchingInfo &&
                <Style.AreaDiv>

                    <Style.Title>내 매칭 정보</Style.Title>

                    <Style.Table>
                        {matchingInfo?.map(elem =>
                            <Style.Elem>
                                <Style.ElemLabelDiv>
                                    <Style.ElemLabel>{elem?.label}</Style.ElemLabel>
                                </Style.ElemLabelDiv>
                                <Style.ElemValueDiv>
                                    <Style.ElemValue $type={elem?.type}>{elem?.value}</Style.ElemValue>
                                </Style.ElemValueDiv>
                            </Style.Elem>
                        )}
                    </Style.Table>

                    <Style.ButtonDiv>
                        <Style.ButtonArea>
                            <Button clickEvent={() => navigate("/matching")}>다시 입력하기</Button>
                        </Style.ButtonArea>
                    </Style.ButtonDiv>

                </Style.AreaDiv>
            }

        </Style.WrapperDiv>
    )
}

export default Mypage