import { useNavigate } from "react-router-dom"

import Style from "./style"
import useMatchingInfoRequest from "../../feature/matching/useMatchingInfo"
import useMatchingResultRequest from "../../feature/matching/useMatchingResultList"
import background from "./assets/background.svg"

const MatchingResult = () => {
    const navigate = useNavigate()

    const [matchingInfo] = useMatchingInfoRequest()
    const [matchingResult] = useMatchingResultRequest()

    return (
        <Style.WrapperDiv>

            <Style.Title>고객님의 상황에 딱 맞는<br /><span>매물</span>들을 골라봤어요!</Style.Title>

            {/* 내 매칭 정보 */}
            <Style.InfoDiv>
                <Style.InfoImg src={background} />
                <Style.InfoList>
                    <Style.InfoTitle>나의 매칭 정보</Style.InfoTitle>
                    {matchingInfo?.map(elem =>
                        <Style.Info>
                            <Style.InfoLabel>{elem.label}</Style.InfoLabel>
                            <Style.InfoValue>{elem.value}</Style.InfoValue>
                        </Style.Info>
                    )}
                </Style.InfoList>
            </Style.InfoDiv>

            {/* 매칭 결과 목록 */}
            <Style.ChapterDiv>

                <Style.ChapterTitleBox>
                    <Style.ChapterTitle>매물 결과</Style.ChapterTitle>
                    <Style.ChapterTitleValue>{matchingResult?.length || 0}</Style.ChapterTitleValue>
                </Style.ChapterTitleBox>

                {/* 매물 목록 */}
                {matchingResult?.map(elem =>
                    <Style.Chpater>

                        {/* 매물 기본 정보 */}
                        <Style.ChapterInfoDiv>
                            <Style.ChapterTitle>{elem.name}</Style.ChapterTitle>
                            <Style.ChapterElemList>
                                {elem?.info?.map(elem2 =>
                                    <Style.ChapterElemDiv>
                                        <Style.ChapterElemLabel>{elem2.label}</Style.ChapterElemLabel>
                                        <Style.ChapterElemValue>{elem2.value}</Style.ChapterElemValue>
                                    </Style.ChapterElemDiv>
                                )}
                            </Style.ChapterElemList>
                        </Style.ChapterInfoDiv>

                        {/* 매물 상세보기 버튼 */}
                        <Style.ChapterControlDiv>
                            <Style.ChapterDetailBtn onClick={() => navigate(`/matching-detail/${elem?.idx}`)}>자세히보기</Style.ChapterDetailBtn>
                        </Style.ChapterControlDiv>

                    </Style.Chpater>
                )}

                {matchingResult?.length < 1 &&
                    <Style.ChapterTitleValue>입력하신 조건에 일치하는 매물이 없습니다.</Style.ChapterTitleValue>
                }

            </Style.ChapterDiv>

        </Style.WrapperDiv>
    )
}

export default MatchingResult