import { useNavigate, useParams } from "react-router-dom"

import Style from "./style"
import useContentRequest from "../../feature/content/useContentDetail"

import Button from "shared/ui/Button"
import convertTimestamp from "shared/lib/convertTimestamp"
import useAuthCookie from "shared/model/useAuthCookie"
import useLoginCheckCookie from "shared/model/useLoginCheckCookie"

const ContentDetail = () => {
    const navigate = useNavigate()
    const params = useParams()
    const contentIdx = params.idx

    const [cookie, createCookie] = useLoginCheckCookie()
    const [authCookie] = useAuthCookie()
    const [getContentDetail] = useContentRequest(contentIdx)

    if (!cookie) createCookie(contentIdx)

    return (
        <Style.WholeDiv>

            {!authCookie && cookie !== parseInt(contentIdx) &&
                <Style.Overlay>
                    <Style.OverlayTitle>로그인 후 모든 콘텐츠의 무료 이용이 가능합니다.</Style.OverlayTitle>
                    <Style.ButtonDiv>
                        <Button clickEvent={() => navigate("/login")}>로그인 하기</Button>
                    </Style.ButtonDiv>
                </Style.Overlay>
            }

            <Style.WrapperDiv available={authCookie || cookie === parseInt(contentIdx) ? true : false}>

                <Style.ThumbnailImg src={getContentDetail?.mainImageUrl} />

                <Style.TitleDiv>
                    <Style.Title>{getContentDetail?.title}</Style.Title>

                    <Style.InfoDiv>
                        <Style.InfoElem>
                            <Style.InfoTitle>작성자</Style.InfoTitle>
                            <Style.InfoContent>{getContentDetail?.author}</Style.InfoContent>
                        </Style.InfoElem>
                        <Style.InfoElem>
                            <Style.InfoTitle>작성날짜</Style.InfoTitle>
                            <Style.InfoContent>{convertTimestamp(getContentDetail?.createdAt)}</Style.InfoContent>
                        </Style.InfoElem>
                    </Style.InfoDiv>
                </Style.TitleDiv>

                <Style.ContentText>{getContentDetail?.paragraph}</Style.ContentText>

            </Style.WrapperDiv>
        </Style.WholeDiv>
    )
}

export default ContentDetail