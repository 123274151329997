import Style from "./style"
import img1 from "./asset/banner (1).png"
import img2 from "./asset/banner (2).png"
import img3 from "./asset/banner (3).png"
import img4 from "./asset/banner (4).png"
import img6 from "./asset/banner (6).png"
import img7 from "./asset/banner (7).png"
import img8 from "./asset/banner (8).png"
import img9 from "./asset/banner (9).png"
import img10 from "./asset/banner (10).png"
import img11 from "./asset/banner (11).png"
import img12 from "./asset/banner (12).png"
import img13 from "./asset/banner (13).png"
import img14 from "./asset/banner (14).png"
import img15 from "./asset/banner (15).png"
import img16 from "./asset/banner (16).png"
import img17 from "./asset/banner (17).png"
import img18 from "./asset/banner (18).png"
import img19 from "./asset/banner (19).png"
import img20 from "./asset/banner (20).png"
import img21 from "./asset/banner (21).png"
import img22 from "./asset/banner (22).png"
import img23 from "./asset/banner (23).png"
import img24 from "./asset/banner (24).png"

const CompanyList = () => {

    const logoList = [img1, img2, img3, img4, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23, img24]

    const slickSetting = {
        "dots": false,
        "arrows": false,
        "infinite": true,
        "draggable": false,
        "pauseOnHover": false,
        "pauseOnFocus": false,
        "autoplay": true,
        "speed": 2000,
        "autoplaySpeed": 2000,
        "variableWidth": true,
        "slidesToScroll": 1,
        "cssEase": "linear"
    }

    return (
        <Style.WrapperDiv>
            <Style.Slider {...slickSetting}>
                {logoList.map(elem =>
                    <Style.Logo src={elem} />
                )}
            </Style.Slider>
        </Style.WrapperDiv>
    )
}

export default CompanyList