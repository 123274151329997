import { useState } from "react"
import { useNavigate } from "react-router-dom"

import useAuthCookie from "./useAuthCookie"

const useFetch = () => {
    const navigate = useNavigate()

    const [data, setData] = useState()

    const [cookie, , deleteCookie] = useAuthCookie()

    const request = async (method, path, body, formdata) => {

        try {
            const object = {
                "method": method,
                "headers": {
                    "Authorization": `Bearer ${cookie}`,
                },
                "body": null
            }
            if (body) {
                object.headers["Content-Type"] = "application/json"
                object.body = JSON.stringify(body)
            } else if (formdata) {
                object.body = formdata
            }

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${path}`, object)
            const result = await response.json()

            if (result?.code === 401 || result?.code === 403) {
                deleteCookie()
                navigate("/")
            }
            else if (result?.code === 500) {
                navigate("/500")
            }
            else {
                setData(result)
            }

            if (process.env.REACT_APP_MODE === "develop") {
                console.group(`${method} ${path}`)
                console.log(object)
                console.log(result?.code, result?.message)
                console.log(result?.data)
                console.groupEnd()
            }

        } catch (err) {
            console.log(err)
        }
    }

    return [data, request]
}

export default useFetch