import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import useFetch from "shared/model/useFetch"
import useAuthCookie from "shared/model/useAuthCookie"
import useConfirmModalAtom from "shared/model/useConfirmModalAtom"

const useDeleteWithdraw = () => {
    const navigate = useNavigate()
    const [, , deleteAuthCookie] = useAuthCookie()
    const [, , , resetModal] = useConfirmModalAtom()
    const [backendState, setBackendState] = useFetch()

    const clickEvent = () => {
        setBackendState("DELETE", `/users`)
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                deleteAuthCookie()
                resetModal()
                navigate("/")
                // 쿠키 삭제, 모달 닫기
                break
        }
    }, [backendState])

    return [clickEvent]
}

export default useDeleteWithdraw