import { useState, useEffect } from "react"

import useTextModalAtom from "shared/model/useTextModalAtom"

const useKakaoEvent = () => {
    const [kakaoAgree, setKakaoAgree] = useState(false)

    const [, setModal] = useTextModalAtom()

    const clickEvent = () => {
        setKakaoAgree(!kakaoAgree)
    }

    useEffect(() => {
        if (kakaoAgree) setModal("카카오 알림톡이 설정되었습니다.")
    }, [kakaoAgree])

    return [kakaoAgree, clickEvent]
}

export default useKakaoEvent