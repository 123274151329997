import { Routes, Route } from "react-router-dom"

import Style from "./style"
import { PublicRoute, PrivateRoute } from "./RouterCondition"

import Home from "./home"
import ContentDetail from "./contentDetail"
import Login from "./login"
import Term from "./term"
import Signup from "./signup"
import SignupPre from "./signupPre"
import SignupDone from "./signupDone"
import IdFind from "./idFind"
import PwFind from "./pwFind"
import Matching from "./matching"
import MatchingResult from "./matchingResult"
import MatchingDetail from "./matchingDetail"
import Consulting from "./consulting"
import Mypage from "./mypage"
import MypageEdit from "./mypageEdit"
import NotFound from "./notFound"
import InternalServerError from "./internalServerError"

const Router = () => {

    return (
        <Style.WrapperDiv>
            <Routes>

                {/* 상관 없음 */}
                <Route index element={<Home />} />
                <Route path="/content-detail/:idx" element={<ContentDetail />} />
                <Route path="/term/:type" element={<Term />} />

                {/* 비로그인 상태 필수 */}
                <Route element={<PublicRoute />}>
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/signup-pre" element={<SignupPre />} />
                    <Route path="/signup-done" element={<SignupDone />} />
                    <Route path="/id-find" element={<IdFind />} />
                    <Route path="/pw-find" element={<PwFind />} />
                </Route>

                {/* 로그인 상태 필수 */}
                <Route element={<PrivateRoute />}>
                    <Route path="/matching" element={<Matching />} />
                    <Route path="/matching-result" element={<MatchingResult />} />
                    <Route path="/matching-detail/:idx" element={<MatchingDetail />} />
                    <Route path="/consulting/:propertyIdx" element={<Consulting />} />
                    <Route path="/mypage" element={<Mypage />} />
                    <Route path="/mypage-edit" element={<MypageEdit />} />
                </Route>

                {/* 상관 없음 */}
                <Route path="/500" element={<InternalServerError />} />
                <Route path="*" element={<NotFound />} />

            </Routes>
        </Style.WrapperDiv >
    )
}

export default Router