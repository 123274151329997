import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import useFetch from "shared/model/useFetch"

const useBannerList = () => {
    const navigate = useNavigate()

    const [backendState, setBackendState] = useFetch()
    const [convertState, setConvertState] = useState()

    useEffect(() => {
        setBackendState("GET", `/contents/banners`)
    }, [])

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                setConvertState(backendState.data)
                break
            case 404:
                navigate(`/404`)
                break
        }
    }, [backendState])


    return [convertState]
}

export default useBannerList