import styled, { css } from "styled-components"

export default {
    "WrapperDiv": styled.div`
        width: 90%;
        max-width: 1200px;
        padding: clamp(40px, 15vw, 120px) 0;
        margin-left: 80px;
        display: flex;
        justify-content: space-between;
        align-items: start;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                margin-left: 0;
            }
        }
    `,
    "Form": styled.div`
        width: 440px;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                width: 100%;
            }
        }
    `,
    "Title": styled.h1`
        ${({ theme }) => theme.font.extra_large_bold};
        span {
            ${({ theme }) => theme.font.extra_large_bold};
            color: ${({ theme }) => theme.color.major};
        }
    `,
    "Explain": styled.p`
        ${({ theme }) => theme.font.small};
        color: ${({ theme }) => theme.color.placeholder};
        line-height: 24px;
        margin-top: 16px;
    `,
    "InputDiv": styled.div`
        margin-top: 48px;
        display: flex;
        flex-direction: column;
        gap: clamp(8px, 3vw, 24px);
    `,
    "BackgroundImg": styled.img`
        width: 680px;
        
        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                display: none;
            }
        }
    `,

    "SubmitDiv": styled.div`
        margin-top: clamp(40px, 10vw, 80px);
        display: flex;
        flex-direction: column;
        align-items: center;
    `,
    "RadioDiv": styled.div`
        display: flex;
        gap: 40px;
        justify-content: center;
    `,
    "RadioElem": styled.button`
        width: fit-content;
        display: flex;
        align-items: center;
        gap: 12px;
    `,
    "RadioLabel": styled.p`
        ${({ theme }) => theme.font.small_bold};
        ${({ $on }) => $on && css`
            color:  ${({ theme }) => theme.color.major};
        `}
    `,
    "ButtonDiv": styled.div`
        margin: 32px 0 16px 0;
    `
}