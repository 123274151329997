import { useEffect } from "react"
import { atom, useResetRecoilState } from "recoil"
import { useRecoilState } from "recoil"

const useConfirmModalAtom = () => {

    const confirmModalAtom = atom({
        "key": "confirmModalAtom",
        "default": null
    })
    const confirmEventAtom = atom({
        "key": "confirmEventAtom",
        "default": null
    })

    const [state, setState] = useRecoilState(confirmModalAtom)
    const resetState = useResetRecoilState(confirmModalAtom)

    const [confirm, setConfirm] = useRecoilState(confirmEventAtom)
    const resetConfirmState = useResetRecoilState(confirmEventAtom)

    const setModalEvent = (label, event) => {
        setState(label)
        setConfirm(event)
    }

    const resetConfirm = () => {
        resetState()
        resetConfirmState()
    }

    return [state, confirm, setModalEvent, resetConfirm]
}

export default useConfirmModalAtom