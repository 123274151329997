import { useState, useEffect } from "react"

import useFetch from "shared/model/useFetch"
import useTextModalAtom from "shared/model/useTextModalAtom"
import regx from "shared/constant/regx"

const useCheckIdDuplicate = (idRef) => {
    const [backendState, setBackendState] = useFetch()
    const [isDuplicate, setIdDuplicate] = useState(true)
    const [, setModal] = useTextModalAtom()

    const exception = (id) => {
        if (!id.match(regx.max20)) {
            setModal("아이디 값을 확인해주세요.")
            return false
        }
        return true
    }

    const clickEvent = () => {
        const id = idRef?.current?.value

        if (exception(id)) {
            setBackendState("GET", `/account/check?account=${id}`)
        }
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                setIdDuplicate(false)
                break
            case 400:
                setModal("중복된 아이디가 존재합니다.")
                setIdDuplicate(true)
                break
        }
    }, [backendState])

    return [isDuplicate, clickEvent]
}

export default useCheckIdDuplicate