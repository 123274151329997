import { useState } from "react"

// ** Params 
// 1. 테스트할 값 : string
// 2. 정규표현식 : regx
const useRegxCheck = (value, regx) => {
    const [isNormal, setIsNormal] = useState(true)

    const blurEvent = () => {
        !value?.current?.value.match(regx)
            ? setIsNormal(false)
            : setIsNormal(true)
    }

    return [isNormal, blurEvent]
}

export default useRegxCheck