import { useEffect } from "react"

import useFetch from "shared/model/useFetch"
import useAuthCookie from "shared/model/useAuthCookie"
import useTextModalAtom from "shared/model/useTextModalAtom"
import regx from "shared/constant/regx"

const usePostLogin = (idRef, pwRef) => {
    const [backendState, setBackendState] = useFetch()
    const [, setAuthCookie] = useAuthCookie()
    const [, setModal] = useTextModalAtom()

    const exception = (id, pw) => {
        if (!id.match(regx.max20)) {
            setModal("아이디 입력 조건을 확인해주세요.")
            return false
        }
        if (!pw.match(regx.password)) {
            setModal("비밀번호 입력 조건을 확인해주세요.")
            return false
        }
        return true
    }

    const postRequest = () => {
        const id = idRef?.current?.value
        const pw = pwRef?.current?.value

        if (exception(id, pw)) {
            setBackendState("POST", `/account/signin`, {
                "account": id,
                "password": pw
            })
        }
    }

    const clickEvent = () => postRequest()

    const enterKeyEvent = (e) => e.key === "Enter" && postRequest()

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                setAuthCookie(backendState?.data?.accessToken)
                break
            case 400:
                setModal("사용 정지된 계정입니다.")
                break
            case 404:
                setModal("회원 정보가 존재하지 않습니다.")
                break
        }
    }, [backendState])

    return [clickEvent, enterKeyEvent]
}

export default usePostLogin