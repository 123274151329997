import { useEffect } from "react"

import useFetch from "shared/model/useFetch"
import useAuthCookie from "shared/model/useAuthCookie"
import useTextModalAtom from "shared/model/useTextModalAtom"

const useAutoLoginRequest = () => {
    const [getAccountState, setGetAccountState] = useFetch()
    const [authCookie, , removeAuthCookie] = useAuthCookie()
    const [, setModal] = useTextModalAtom()

    // 쿠키 있을 때 이름 알아오기 Request
    useEffect(() => {
        if (authCookie) {
            setGetAccountState("POST", "/account/auth", {
                "accessToken": `${authCookie}`
            })
        }
    }, [authCookie])

    // 자동 로그인 Request 후처리
    useEffect(() => {
        switch (getAccountState?.code) {
            case 400:
                setModal("사용 정지된 계정입니다.")
                removeAuthCookie()
                break
            case 404:
                setModal("세션이 만료되었습니다. \n다시 로그인해주세요")
                removeAuthCookie()
                break
        }
    }, [getAccountState])
}

export default useAutoLoginRequest