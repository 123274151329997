import styled, { css } from "styled-components"

export default {
    "WrapperDiv": styled.main`
        width: 90%;
        max-width: 920px;
        padding: clamp(40px, 15vw, 120px) 0;
        display: flex;
        flex-direction: column;
        gap: clamp(40px, 10vw, 80px);
    `,
    "AreaDiv": styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
    `,
    "Title": styled.h2`
        ${({ theme }) => theme.font.medium_bold};
    `,

    "Table": styled.div`
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid ${({ theme }) => theme.color.black};
        margin-top: 32px;
    `,
    "Elem": styled.div`
        height: 56px;
        display: flex;
        flex-grow: 1;
        border-top: 1px solid ${({ theme }) => theme.color.black};
    `,
    "ElemLabelDiv": styled.div`
        display: flex;
        align-items: center;
        padding-left: 24px;
        width: clamp(160px, 25vw, 200px);
        background-color: ${({ theme }) => theme.color.light_major};

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                padding-left: 0px;
                justify-content: center;
            }
        }
    `,
    "ElemLabel": styled.h2`
        ${({ theme }) => theme.font.small_bold};
    `,
    "ElemValueDiv": styled.div`
        display: flex;
        align-items: center;
        flex-grow: 1;
        background-color: ${({ theme }) => theme.color.white};
    `,
    "ElemValue": styled.p`
        ${({ theme }) => theme.font.small};
        padding-left: 24px;
        ${({ $type }) => $type === "hide" && css`
            color: ${({ theme }) => theme.color.placeholder};
        `}
    `,

    "ButtonDiv": styled.div`
        display: flex;
        justify-content: center;
        gap: clamp(16px, 5vw, 40px);
        margin-top: 32px;
    `,
    "ButtonArea": styled.div`
        width: 280px;
    `
}