import { useState, useEffect } from "react"

import useFetch from "shared/model/useFetch"

const useMyInfo = () => {

    const [backendState, setBackendState] = useFetch()
    const [mapper, setMapper] = useState(null)

    useEffect(() => {
        setBackendState("GET", `/users/information`)
    }, [])

    useEffect(() => {
        const data = backendState?.data

        switch (backendState?.code) {
            case 200:
                setMapper([
                    {
                        "label": "아이디",
                        "value": data.account
                    },
                    {
                        "label": "이름",
                        "value": data.name
                    },
                    {
                        "label": "연락처",
                        "value": data.phone
                    },
                    {
                        "label": "이메일",
                        "value": data.email
                    }
                ])
        }
    }, [backendState])

    return [mapper, backendState]
}

export default useMyInfo