import styled, { css } from "styled-components"

export default {
    "WholeDiv": styled.div`
        position: relative;
        display: flex;
        justify-content: center;
    `,

    "Overlay": styled.div`
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        gap: clamp(20px, 5vw, 40px);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: linear-gradient(
            rgba(0, 0, 0, 0) 40%, 
            rgba(0, 0, 0, 0.9) 100%
        );
    `,
    "OverlayTitle": styled.h2`
        ${({ theme }) => theme.font.medium_bold};
        color: ${({ theme }) => theme.color.white};
    `,
    "ButtonDiv": styled.div`
        width: 90%;
        max-width: 320px;
        margin-bottom: clamp(80px, 20vw, 160px);
    `,

    "WrapperDiv": styled.div`
        width: 90%;
        max-width: 1200px;
        padding: clamp(40px, 15vw, 120px) 0;

        ${({ available }) => {
            if (!available) return css`
                overflow-y: hidden;
                max-height: 1300px;

                @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
                    & {
                        max-height: 800px;
                    }
                }
            `
        }}
    `,
    "ThumbnailImg": styled.img`
        width: 100%;
        height: clamp(200px, 45vw, 360px);
        border-radius: 20px;
        object-fit: cover;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                border-radius: 8px;
            }
        }
    `,
    "TitleDiv": styled.div`
        padding: clamp(24px, 6vw, 48px) 0;
        margin-bottom: clamp(24px, 6vw, 48px);
        border-bottom: 1px solid ${({ theme }) => theme.color.line};
    `,
    "Title": styled.h1`
        ${({ theme }) => theme.font.large_bold};
    `,
    "InfoDiv": styled.div`
        width: fit-content;
        margin-top: 24px;
        display: flex;
        gap: 40px;
    `,
    "InfoElem": styled.div`
        display: flex;
        gap: 24px;
    `,
    "InfoTitle": styled.p`
        ${({ theme }) => theme.font.extra_small};
        color: ${({ theme }) => theme.color.placeholder};
        white-space: nowrap;
    `,
    "InfoContent": styled.p`
        ${({ theme }) => theme.font.extra_small};
        white-space: nowrap;
    `,

    "ContentText": styled.p`
        ${({ theme }) => theme.font.small};
        line-height: 180%;
    `,
}