import { useParams, useNavigate } from "react-router-dom"

import Style from "./style"
import { privacy, service, marketing } from "./asset/data"

import Button from "shared/ui/Button"

const Term = () => {
    const navigate = useNavigate()
    const params = useParams()
    const type = params.type

    const targetData = {
        "privacy": {
            "title": "개인정보처리방침",
            "content": privacy
        },
        "service": {
            "title": "서비스이용약관",
            "content": service
        },
        "marketing": {
            "title": "마케팅수신동의",
            "content": marketing
        }
    }

    return (
        <Style.WrapperDiv>
            <Style.Title>{targetData[type].title}</Style.Title>
            <Style.Content>{targetData[type].content}</Style.Content>
            <Style.ButtonDiv>
                <Button clickEvent={() => navigate("/")}>홈으로 돌아가기</Button>
            </Style.ButtonDiv>
        </Style.WrapperDiv>
    )
}

export default Term