import styled from "styled-components"

export default {
    "WrapperDiv": styled.main`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 80px 0;
    `,
    "Title": styled.h1`
        ${({ theme }) => theme.font.medium_bold};
    `,
    "Content": styled.p`
        ${({ theme }) => theme.font.small};
        margin-top: 4px;
    `,
    "CautionSvg": styled.img`
        width: 520px;
    `
}