import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import useFetch from "shared/model/useFetch"
import useTextModalAtom from "shared/model/useTextModalAtom"

const usePropertyDetail = (propertyIdx) => {
    const navigate = useNavigate()

    const [backendState, setBackendState] = useFetch()
    const [mapper, setMapper] = useState(null)

    const [, setModal] = useTextModalAtom()

    useEffect(() => {
        setBackendState("GET", `/matchings/detail?propertyId=${propertyIdx}`)
    }, [])

    useEffect(() => {
        const data = backendState?.data

        switch (backendState?.code) {
            case 200:
                setMapper({
                    "idx": data.propertyId,
                    "name": data.propertyName,
                    "info": [
                        {
                            "label": "지역",
                            "value": data.region
                        },
                        {
                            "label": "보증금",
                            "type": "hide",
                            "value": "비공개 정보입니다."
                        },
                        {
                            "label": "주소",
                            "value": data.address
                        },
                        {
                            "label": "권리금",
                            "type": "hide",
                            "value": "비공개 정보입니다."
                        },
                        {
                            "label": "업종",
                            "value": data.sector
                        },
                        {
                            "label": "임대료",
                            "value": `${data.rent / 10000}만원`
                        },
                        {
                            "label": "내 예산",
                            "value": `${data.minCost / 10000}만원 ~ ${data.maxCost / 10000}만원`
                        },
                        {
                            "label": "로열티",
                            "type": "hide",
                            "value": "비공개 정보입니다."
                        },
                        {
                            "label": "면적",
                            "value": `${data.area}m² ( ${(data.area / 3.3).toFixed(2)}평 ) ${data.floor < 0 ? "지하" : ""} ${Math.abs(data.floor)}층`
                        },
                        {
                            "label": "임대 명의",
                            "type": "hide",
                            "value": "비공개 정보입니다."
                        },

                    ]
                })
                break
            case 400:
                setModal("입력 값이 잘못되었습니다.")
                break
            case 404:
                navigate("/404")
                break
        }

    }, [backendState])

    return [mapper]
}

export default usePropertyDetail