import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import useFetch from "shared/model/useFetch"

const useContentDetail = (idx) => {
    const navigate = useNavigate()

    const [backendState, setBackendState] = useFetch()

    useEffect(() => {
        setBackendState("GET", `/contents/detail?contentId=${idx}`)
    }, [])

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                break
            case 404:
                navigate(`/404`)
                break
        }
    }, [backendState])


    return [backendState?.data]
}

export default useContentDetail