import styled from "styled-components"

export default {
    "WrapperDiv": styled.main`
        width: 90%;
        max-width: 420px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: clamp(40px, 17vw, 136px) 0 clamp(40px, 20vw, 160px) 0;
    `,
    "Title": styled.h2`
        ${({ theme }) => theme.font.large_bold};
    `,
    "Form": styled.div`
        display: flex;
        flex-direction: column;
        gap: clamp(12px, 3vw, 24px);
        margin-top: clamp(24px, 6vw, 48px);
    `,
    "ButtonDiv": styled.div`
        display: flex;
        flex-direction: column;
        align-items: end;
        gap: 8px;
        margin-top: clamp(40px, 8.5vw, 68px);
    `,
}