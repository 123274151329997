import { useState } from "react"

const useIsInputChange = () => {
    const [isChange, setIsChange] = useState(false)

    const changeEvent = () => {
        if (!isChange) {
            setIsChange(true)
        }
    }

    return [isChange, changeEvent]
}

export default useIsInputChange