import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import useFetch from "shared/model/useFetch"
import useTextModalAtom from "shared/model/useTextModalAtom"
import regx from "shared/constant/regx"

const usePostSignup = (idRef, pwRef, pwConfirmRef, nameRef, contactRef, confirmToken, emailRef, ...certificatedList) => {
    const navigate = useNavigate()
    const [backendState, setBackendState] = useFetch()
    const [, setModal] = useTextModalAtom()

    const exception = (id, pw, pwConfirm, name, contact, email, checkList) => {
        if (!id.match(regx.max20)) {
            setModal("아이디 값을 확인해주세요.")
            return false
        }
        if (!pw.match(regx.password)) {
            setModal("비밀번호 값을 확인해주세요.")
            return false
        }
        if (pw !== pwConfirm) {
            setModal("비밀번호가 일치하는지 확인해주세요.")
            return false
        }
        if (!name.match(regx.max20)) {
            setModal("이름 값을 확인해주세요.")
            return false
        }
        if (!contact.match(regx.contact)) {
            setModal("연락처 값을 확인해주세요.")
            return false
        }
        if (!email.match(regx.email)) {
            setModal("이메일 값을 확인해주세요.")
            return false
        }

        // 아이디 중복 체크 여부, 연락처 인증 여부
        if (checkList.includes(false)) {
            setModal("모든 인증을 진행해주세요.")
            return false
        }

        return true
    }

    const clickEvent = () => {
        const id = idRef?.current?.value
        const pw = pwRef?.current?.value
        const pwConfirm = pwConfirmRef?.current?.value
        const name = nameRef?.current?.value
        const contact = contactRef?.current?.value
        const token = confirmToken
        const email = emailRef?.current?.value

        if (exception(id, pw, pwConfirm, name, contact, email, certificatedList)) {
            setBackendState("POST", `/account/signup`, {
                "account": id,
                "password": pw,
                "name": name,
                "phone": contact,
                "email": email,
                "authToken": token
            })
        }
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                navigate("/signup-done")
                break
            case 400:
                setModal("중복된 연락처가 존재합니다.")
                break
            case 404:
                setModal("연락처 인증이 정상적으로 진행되지 않았습니다.")
                break
        }
    }, [backendState])

    return [clickEvent]
}

export default usePostSignup