import { useEffect } from "react"

import useFetch from "shared/model/useFetch"
import useTextModalAtom from "shared/model/useTextModalAtom"
import regx from "shared/constant/regx"

const usePostMatching = (addressRef, sectorRef, minCostRef, maxCostRef, minFloorRef, minFloorTypeRef, maxFloorRef, maxFloorTypeRef, minAreaRef, maxAreaRef, customerType, kakaoAgree) => {
    const [backendState, setBackendState] = useFetch()
    const [, setModal] = useTextModalAtom()

    const exception = (minCost, maxCost, minFloor, minFloorType, maxFloor, maxFloorType, minArea, maxArea) => {
        if (!minCost.match(regx.number)) {
            setModal("예산 시작 값을 확인해주세요.")
            return false
        }
        if (!maxCost.match(regx.number)) {
            setModal("예산 끝 값을 확인해주세요.")
            return false
        }
        if (parseInt(minCost) > parseInt(maxCost)) {
            setModal("예산 끝 값은 시작 값보다 작을 수 없습니다.")
            return false
        }
        if (!minFloor.match(regx.integer)) {
            setModal("층 시작 값을 확인해주세요.")
            return false
        }
        if (!maxFloor.match(regx.integer)) {
            setModal("층 종료 값을 확인해주세요.")
            return false
        }
        if (parseInt(minFloorType === "지하" ? "-" + minFloor : minFloor) > parseInt(maxFloorType === "지하" ? "-" + maxFloor : maxFloor)) {
            setModal("층 끝 값은 시작 값보다 작을 수 없습니다.")
            return false
        }
        if (!minArea.match(regx.double)) {
            setModal("면적 시작 값을 확인해주세요.")
            return false
        }
        if (!maxArea.match(regx.double)) {
            setModal("면적 끝 값을 확인해주세요.")
            return false
        }
        if (parseFloat(minArea) > parseFloat(maxArea)) {
            setModal("면적 끝 값은 시작 값보다 작을 수 없습니다.")
            return false
        }

        return true
    }

    const clickEvent = () => {
        const address = addressRef?.current?.value
        const sector = sectorRef?.current?.value
        const minCost = minCostRef?.current?.value
        const maxCost = maxCostRef?.current?.value
        const minFloor = minFloorRef?.current?.value
        const minFloorType = minFloorTypeRef?.current?.value
        const maxFloor = maxFloorRef?.current?.value
        const maxFloorType = maxFloorTypeRef?.current?.value
        const minArea = minAreaRef?.current?.value
        const maxArea = maxAreaRef?.current?.value

        if (exception(minCost, maxCost, minFloor, minFloorType, maxFloor, maxFloorType, minArea, maxArea)) {

            // MEMO: floor 값은 -가 붙으면 지하를 의미 함 ( 기본값은 지상으로 취급 )
            setBackendState("POST", `/matchings`, {
                "region": address,
                "sector": sector,
                "minCost": minCost * 10000,
                "maxCost": maxCost * 10000,
                "minFloor": minFloorType === "지하" ? "-" + minFloor : minFloor,
                "maxFloor": maxFloorType === "지하" ? "-" + maxFloor : maxFloor,
                "minArea": minArea,
                "maxArea": maxArea,
                "kakaoSignalSet": kakaoAgree,
                "userRole": customerType
            })
        }
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                // MEMO: 기존 navigate() 에서 새 창으로 열리는 것으로 변경
                window.open("/matching-result")
                break
            case 400:
                setModal("입력 값이 올바르지 않습니다.")
                break
        }
    }, [backendState])

    return [clickEvent]
}

export default usePostMatching