import styled from "styled-components"

export default {
    "Wrapper": styled.footer`
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 0;
    `,
    "LogoImg": styled.img`
        width: 80px;
    `,
    "InfoDiv": styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 32px;
        gap: 4px;
    `,
    "InfoTitle": styled.h1`
        ${({ theme }) => theme.font.extra_small_bold};
    `,
    "InfoContent": styled.p`
        ${({ theme }) => theme.font.extra_small};
        color: ${({ theme }) => theme.color.placeholder};
    `,
    "TermDiv": styled.div`
        width: fit-content;
        display: flex;
        gap: 40px;
        margin-top: 32px;
    `,
    "TermBtn": styled.button`
        ${({ theme }) => theme.font.extra_small_bold};
        color: ${({ theme }) => theme.color.placeholder};
    `,
    "CopyrightLabel": styled.p`
        ${({ theme }) => theme.font.extra_small_bold};
        color: ${({ theme }) => theme.color.placeholder};
        margin-top: 16px;
    `
}