import Style from "./style"
import icon from "./assets/icon.svg"

// value ~ required : 기본 값
// blurEvent ~ exceptionMsg : 프론트엔드 예외처리 때 필요
// btnValue ~ clickEvent : button 형태로 출력할 때 필요
// isPass : 아이디 중복 체크, 연락처 인증 등에 필요
// optionList : select 형태로 출력할 때 필요
const LabelInput = ({
    value, initValue, type = "text", placeholder, inputRef, changeEvent, required,
    blurEvent, isNormal, exceptionMsg,
    btnValue, clickEvent, isPass, passMsg,
    optionList,
}) => {

    return (
        <Style.Wrapper>

            {required
                ? <Style.InputLabel>{value}<span>*</span></Style.InputLabel>
                : <Style.InputLabel>{value}</Style.InputLabel>
            }

            {type === "select"
                ? <Style.SelectDiv >
                    <Style.Select ref={inputRef} src={icon}>
                        {optionList?.map((elem, index) => <option value={elem} selected={optionList.indexOf(value) === index}>{elem}</option>)}
                    </Style.Select>
                </Style.SelectDiv>
                : <Style.ExceptionDiv>
                    <Style.InputDiv $exception={isNormal}>
                        <Style.Input type={type} defaultValue={initValue} ref={inputRef} placeholder={placeholder} onChange={changeEvent} onBlur={blurEvent} readOnly={isPass} />
                        {btnValue && <Style.Button onClick={clickEvent}>{btnValue}</Style.Button>}
                    </Style.InputDiv>
                    {!isNormal && <Style.Exception>{exceptionMsg}</Style.Exception>}
                    {isPass && <Style.Certification>{passMsg}</Style.Certification>}
                </Style.ExceptionDiv>
            }


        </Style.Wrapper>
    )
}

export default LabelInput