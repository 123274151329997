import React from "react";

import Style from "./style"

import useConfirmModalAtom from "shared/model/useConfirmModalAtom";

const TextModal = () => {

    const [label, confirmEvent, , resetState] = useConfirmModalAtom()

    return (
        <Style.BgDiv>
            <Style.ModalDiv>
                <Style.Label>{label}</Style.Label>
                <Style.BtnDiv>
                    <Style.Btn onClick={confirmEvent} left>확인</Style.Btn>
                    <Style.Btn onClick={resetState} right>취소</Style.Btn>
                </Style.BtnDiv>
            </Style.ModalDiv>
        </Style.BgDiv>
    )
}

export default TextModal