import { useNavigate, useParams } from "react-router-dom"

import Style from "./style"
import usePropertyRequest from "../../feature/property/usePropertyDetail"
import Button from "shared/ui/Button"

const MatchingDetail = () => {
    const navigate = useNavigate()
    const params = useParams()
    const propertyIdx = params.idx

    const [property] = usePropertyRequest(propertyIdx)

    return (
        <Style.WrapperDiv>

            <Style.Title>{property?.name}</Style.Title>

            <Style.Table>

                {property?.info?.map(elem =>
                    <Style.Elem>
                        <Style.ElemLabelDiv>
                            <Style.ElemLabel>{elem?.label}</Style.ElemLabel>
                        </Style.ElemLabelDiv>
                        <Style.ElemValueDiv>
                            <Style.ElemValue $type={elem?.type}>{elem?.value}</Style.ElemValue>
                        </Style.ElemValueDiv>
                    </Style.Elem>
                )}

            </Style.Table>

            <Style.ButtonDiv>
                <Button clickEvent={() => navigate(`/consulting/${propertyIdx}`)}>PM 상담신청</Button>
            </Style.ButtonDiv>

        </Style.WrapperDiv>
    )
}

export default MatchingDetail