import Style from "./style"
import icon from "./assets/icon.svg"

const LabelDropdownInput = ({
    value, unit, placeholderList, inputRefList, required,
    selectRefList, optionList,
    blurEvent, isNormal, exceptionMsg
}) => {

    return (
        <Style.Wrapper>

            {required
                ? <Style.InputLabel>{value}<span>*</span></Style.InputLabel>
                : <Style.InputLabel>{value}</Style.InputLabel>
            }

            <Style.ExceptionDiv>
                <Style.InputDiv>
                    <Style.Elem>
                        <Style.Select ref={selectRefList[0]} src={icon}>
                            {optionList?.map((elem) => <option value={elem}>{elem}</option>)}
                        </Style.Select>
                        <Style.Input ref={inputRefList[0]} placeholder={placeholderList && placeholderList[0]} onBlur={blurEvent[0]} $exception={isNormal[0]} />
                        <Style.Unit>{unit}~</Style.Unit>
                    </Style.Elem>
                    <Style.Elem>
                        <Style.Select ref={selectRefList[1]} src={icon}>
                            {optionList?.map((elem) => <option value={elem}>{elem}</option>)}
                        </Style.Select>
                        <Style.Input ref={inputRefList[1]} placeholder={placeholderList && placeholderList[1]} onBlur={blurEvent[1]} $exception={isNormal[1]} />
                        <Style.Unit>{unit}</Style.Unit>
                    </Style.Elem>
                </Style.InputDiv>

                {(!isNormal[0] || !isNormal[1]) && <Style.Exception>{exceptionMsg}</Style.Exception>}
            </Style.ExceptionDiv>


        </Style.Wrapper>
    )
}

export default LabelDropdownInput