import { createGlobalStyle } from "styled-components"

const reset = createGlobalStyle`

    * {
        color: ${({ theme }) => theme.color.black};
        line-height: 1;
        box-sizing: border-box;
    }
    
    html, body , header, nav, aside, main, section, article, footer, div {
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        padding: 0;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                font-size: clamp(12px, 2.06vw, 16px);
            }
        }
    }

    h1, h2, h3, p, span {
        width: fit-content;
        min-height: 0;
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 400;
        white-space: pre-wrap;
    }

    a {
        width: fit-content;
        text-decoration: none;
        cursor: pointer;
    }

    textarea, input {
        width: fit-content;
        margin: 0;
        padding: 0;
        border: none;
        outline: none;
        resize: none;
    }

    button {
        width: fit-content;
        cursor: pointer;
        margin: 0;
        padding: 0;
        background-color: #00000000;
        border: none;
        outline: none;
        white-space: nowrap;
    }

    select {
        outline: none;
    }
`

export default reset