import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export default {
    "WrapperDiv": styled.section`
        display: flex;
        align-items: center;
        overflow: hidden;
        height: fit-content;
        padding: 9px 0;
        background-color: white;
    `,
    "LogoDiv": styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
    `,
    "Slider": styled(Slider)`
        .slick-track {
            display: flex;
            align-items: center;
            gap: 80px;
            
            @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
                & {
                    gap: 40px;
                }
            }
        }
        .slick-slide {
            width: fit-content;
        }
    `,
    "Logo": styled.img`
        height: 24px;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                height: 16px;
            }
        }
    `
}