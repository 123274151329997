export default {

    // 문자 종류 상관 없이 / 1자 이상 10자 이하
    "max10": /^.{1,10}$/,

    // 문자 종류 상관 없이 / 1자 이상 20자 이하
    "max20": /^.{1,20}$/,

    // 문자 종류 상관 없이 / 1자 이상 50자 이하
    "max50": /^.{1,20}$/,

    // 숫자 최소 1자, 영문 최소 1자, 특수문자 최소 1자 조건 / 1자 이상 20자 이하
    "password": /^(?=.*?[0-9])(?=.*?[a-zA-Z])(?=.*?[#?!@$%^&*-]).{1,20}$/,

    // 숫자 / 6자
    "auth_number": /^[0-9]{6}$/,

    // [숫자 / 2자 이상 3자 이하] - [숫자 / 3자 이상 4자 이하] - [숫자 / 4자]
    "contact": /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}$/,

    // [문자 종류 상관 없이 / 1자 이상 20자 이하] @ [영문 대소문자 / 1자 이상 12자 이하] . [영문 대소문자와 . / 2자 이상 8자 이하]
    "email": /^.{1,20}@[a-zA-Z]{1,12}\.[a-z.]{2,8}$/,

    // 양수
    "number": /^[0-9]+$/,

    // 정수
    "integer": /^[0-9-]+$/,

    // 양의 실수
    "double": /^[0-9.]+$/,

    // [숫자 / 4자] - [숫자 / 2자] - [숫자 / 2자]
    "date": /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,

    // [숫자 / 2자] : [숫자 / 2자]
    "time": /^(0[0-9]|1[0-9]|2[0-3]):(0[1-9]|[0-5][0-9])$/
}