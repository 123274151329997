import styled from "styled-components"

export default {
    "WrapperDiv": styled.main`
        width: 72%;
        max-width: 420px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: clamp(40px, 19vw, 152px) 0 clamp(40px, 20vw, 160px) 0;
    `,
    "Img": styled.img`
        width: 100%;
        max-width: 422px;
    `,
    "Title": styled.h1`
        ${({ theme }) => theme.font.large_bold};
        margin-top: 32px;
    `,
    "Content": styled.p`
        ${({ theme }) => theme.font.small};
        color: ${({ theme }) => theme.color.placeholder};
        margin: 8px 0 48px 0;
    `
}