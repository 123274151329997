import { useNavigate } from "react-router-dom"

import Style from "./style"
import Done from "./asset/done.svg"

import Button from "shared/ui/Button"

const SignupDone = () => {
    const navigate = useNavigate()

    return (
        <Style.WrapperDiv>
            <Style.Img src={Done} />
            <Style.Title>회원가입이 완료되었습니다.</Style.Title>
            <Style.Content>로그인하여 커밍브랜드의 다양한 혜택을 누리세요.</Style.Content>
            <Button clickEvent={() => navigate("/login")}>로그인 하러 가기</Button>
        </Style.WrapperDiv>
    )
}

export default SignupDone