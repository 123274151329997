import { useState, useEffect } from "react"

import useFetch from "shared/model/useFetch"

const useMatchingInfo = () => {

    const [backendState, setBackendState] = useFetch()
    const [mapper, setMapper] = useState(null)

    useEffect(() => {
        setBackendState("GET", `/matchings`)
    }, [])

    useEffect(() => {
        const data = backendState?.data

        switch (backendState?.code) {
            case 200:

                const minFloorConvert = data.minFloor < 0
                    ? `지하 ${Math.abs(data.minFloor)}`
                    : data.minFloor
                const maxFloorConvert = data.maxFloor < 0
                    ? `지하 ${Math.abs(data.maxFloor)}`
                    : data.maxFloor
                const minAreaConvert = (data.minArea / 3.3).toFixed(2)
                const maxAreaConvert = (data.maxArea / 3.3).toFixed(2)

                setMapper([
                    {
                        "label": "지역",
                        "value": data.region,
                    },
                    {
                        "label": "업종",
                        "value": data.sector
                    },
                    {
                        "label": "예산",
                        "value": `${data.minCost / 10000}만원 ~ ${data.maxCost / 10000}만원`
                    },
                    {
                        "label": "층",
                        "value": `${minFloorConvert}층 ~ ${maxFloorConvert}층`
                    },
                    {
                        "label": "면적",
                        "value": `${data.minArea}m² ~ ${data.maxArea}m² ( ${minAreaConvert}평 ~ ${maxAreaConvert}평 )`
                    },
                    {
                        "label": "알림톡 수신 여부",
                        "value": data.kakaoSignalSet ? "수신" : "미수신"
                    }
                ])
                break
        }

    }, [backendState])

    return [mapper]
}

export default useMatchingInfo