import { useState, useEffect } from "react"

import useFetch from "shared/model/useFetch"

const useMatchingResultList = () => {

    const [backendState, setBackendState] = useFetch()
    const [mapper, setMapper] = useState(null)

    useEffect(() => {
        setBackendState("GET", `/matchings/result`)
    }, [])

    useEffect(() => {
        const data = backendState?.data

        switch (backendState?.code) {
            case 200:
                setMapper(
                    data.map(elem => {
                        return {
                            "idx": elem.propertyId,
                            "name": elem.propertyName,
                            "info": [
                                {
                                    "label": "지역",
                                    "value": elem.region
                                },
                                {
                                    "label": "주소",
                                    "value": elem.address
                                },
                                {
                                    "label": "업종",
                                    "value": elem.sector
                                },
                                {
                                    "label": "필요 예산",
                                    "value": `${elem.budget / 10000}만원`
                                },
                                {
                                    "label": "층",
                                    "value": `${elem.floor < 0 ? "지하" : ""} ${Math.abs(elem.floor)}층`
                                },
                                {
                                    "label": "면적",
                                    "value": `${elem.area}m² ( ${(elem.area / 3.3).toFixed(2)}평 )`
                                }
                            ]
                        }
                    })
                )
                break
        }
    }, [backendState])

    return [mapper]
}

export default useMatchingResultList