import { ThemeProvider } from "styled-components"

import Style from "./style"
import theme from "./theme"
import "./global/font.css"
import ResetCSS from "./global/reset"
import Header from "./header"
import Footer from "./footer"
import TextModal from "./textModal"
import ConfirmModal from "./confirmModal"
import useAutoLoginRequest from "./useAutoLoginRequest"
import useScrollToTop from "./useScrollToTop"

import Router from "page"
import useTextModalAtom from "shared/model/useTextModalAtom"
import useConfirmModalAtom from "shared/model/useConfirmModalAtom"

const App = () => {
    const [isTextModal] = useTextModalAtom()
    const [isConfirmModal] = useConfirmModalAtom()

    useScrollToTop()
    useAutoLoginRequest()

    return (
        <ThemeProvider theme={theme}>
            <ResetCSS />
            <Style.ParentDiv>
                {isTextModal && <TextModal />}
                {isConfirmModal && <ConfirmModal />}
                <Header />
                <Router />
                <Footer />
            </Style.ParentDiv>
        </ThemeProvider>
    )
}

export default App