import { useState } from "react"

// 1 : 개인 고객
// 2 : 브랜드 대표
const useCustomerTypeEvent = (typeList) => {
    const [customerType, setCustomerType] = useState(typeList[0])

    const clickEvent = (value) => {
        setCustomerType(value)
    }

    return [customerType, clickEvent]
}

export default useCustomerTypeEvent