import styled, { css } from "styled-components"

export default {
    "WrapperDiv": styled.main`
        width: 90%;
        max-width: 616px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: clamp(48px, 20vw, 160px) 0;
        padding: clamp(40px, 10vw, 80px) 96px;
        background-color: ${({ theme }) => theme.color.white};
        border-radius: 16px;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                padding-left: 5%;
                padding-right: 5%;
            }
        }
    `,
    "Title": styled.h2`
        ${({ theme }) => theme.font.large_bold};
    `,
    "Form": styled.div`
        display: flex;
        flex-direction: column;
        gap: clamp(12px, 3vw, 24px);
        margin-top: clamp(24px, 6vw, 48px);
    `,
    "ButtonDiv": styled.div`
        display: flex;
        flex-direction: column;
        align-items: end;
        gap: 8px;
        margin-top: clamp(24px, 8.5vw, 68px);
    `,


}