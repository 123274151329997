import { useState, useEffect } from "react"

import useFetch from "shared/model/useFetch"
import useTextModalAtom from "shared/model/useTextModalAtom"
import regx from "shared/constant/regx"

// ** Params
// 1. 연락처 input : useRef
const usePostMessage = (contactRef) => {
    const [backendState, setBackendState] = useFetch()
    const [isSend, setIsSend] = useState(false)
    const [, setModal] = useTextModalAtom()

    const exception = (contact) => {
        if (!contact.match(regx.contact)) {
            setModal("연락처 값을 확인해주세요.")
            return false
        }
        return true
    }

    const clickEvent = () => {
        const contact = contactRef?.current?.value

        if (exception(contact)) {
            setBackendState("POST", `/ncp/phone/auth`, {
                "phoneNumber": contact.replaceAll("-", "")
            })
        }
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                setIsSend(true)
                break
            case 400:
                setModal("문자 전송에 실패하였습니다.")
                setIsSend(false)
                break
        }
    }, [backendState])

    return [isSend, backendState, clickEvent]
}

export default usePostMessage