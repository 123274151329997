import { useCookies } from "react-cookie"

const useLoginCheckCookie = () => {

    const cookieName = "FHRMDLSWPGKS"
    const [cookie, setCookie, removeCookie] = useCookies([cookieName])

    const createCookie = (contentIdx) => {
        setCookie(cookieName, contentIdx, {
            "path": "/",
            "expires": new Date(Date.now() + 1000 * 3600 * 24)
        })
    }

    const deleteCookie = () => {
        removeCookie(cookieName, { "path": "/" })
    }

    return [cookie[cookieName], createCookie, deleteCookie]
}

export default useLoginCheckCookie