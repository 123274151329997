import styled from "styled-components"

export default {
    "Wrapper": styled.header`
        height: clamp(56px, 9.3vw, 72px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 200px;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                padding: 0 10%;
            }
        }
    `,
    "LogoImg": styled.img`
        width: 88px;
        cursor: pointer;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                width: clamp(40px, 11vw, 88px);
            }
        }
    `,
    "MenuWrapper": styled.div`
        width: fit-content;
        display: flex;
        align-items: center;
        gap: 48px;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                gap: clamp(8px, 6.2vw, 48px);
            }
        }
    `,
    "Label": styled.p`
        ${({ theme }) => theme.font.small_bold};
        span {
            color: ${({ theme }) => theme.color.major};
        }
    `,
    "MenuBtn": styled.button`   
        ${({ theme }) => theme.font.small_bold};
        border-bottom: 2px solid white;
        transition-property: border, padding;
        transition-duration: 100ms;
        &:hover {
            padding-bottom: 4px;
            border-bottom: 2px solid ${({ theme }) => theme.color.major};
        }
    `
}