import styled from "styled-components"

export default {
    "Button": styled.button`
        width: 100%;
        ${({ theme }) => theme.font.small};
        color: ${({ theme }) => theme.color.white};
        border-radius: 4px;
        background-color: ${({ theme }) => theme.color.major};
        padding: 16px 0;
        transition-property: background-color;
        transition-duration: 0.1s;

        &:hover {
            background-color: ${({ theme }) => theme.color.hover};
        }
    `,
}