import { useEffect } from "react"

import useFetch from "shared/model/useFetch"
import useTextModalAtom from "shared/model/useTextModalAtom"

const useFindId = (confirmToken, ...certificatedList) => {
    const [backendState, setBackendState] = useFetch()
    const [, setModal] = useTextModalAtom()

    const exception = (checkList) => {

        // 연락처 인증 여부
        if (checkList.includes(false)) {
            setModal("모든 인증을 진행해주세요.")
            return false
        }

        return true
    }

    const clickEvent = () => {
        const token = confirmToken

        if (exception(certificatedList)) {
            setBackendState("POST", `/account`, {
                "authToken": token
            })
        }
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 400:
                setModal("입력 값이 잘못되었거나 \n인증 제한시간이 초과하였습니다.")
                break
            case 404:
                setModal("입력하신 정보에 대한 계정이 존재하지 않습니다.")
                break
        }
    }, [backendState])

    return [backendState, clickEvent]
}

export default useFindId