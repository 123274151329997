import { useRef } from "react"

import Style from "./style"
import useChangePwRequest from "../../feature/account/usePutPw"

import useMessageRequest from "feature/message/usePostMessage"
import useContactConfirmRequest from "feature/message/usePostMessageConfirm"
import InputLabel from "shared/ui/LabelInput"
import Button from "shared/ui/Button"
import regx from "shared/constant/regx"
import useRegxCheck from "shared/model/useRegxCheck"
import useCompareCheck from "shared/model/useComapreCheck"

const PwFind = () => {
    const idRef = useRef()
    const contactRef = useRef()
    const contactConfirmRef = useRef()
    const pwRef = useRef()
    const pwConfirmRef = useRef()

    const [isIdNormal, checkIdEvent] = useRegxCheck(idRef, regx.max20)
    const [isContactNormal, checkContactEvent] = useRegxCheck(contactRef, regx.contact)
    const [isContactConfirmNormal, checkContactConfirmEvent] = useRegxCheck(contactConfirmRef, regx.auth_number)
    const [isPwNormal, checkPwEvent] = useRegxCheck(pwRef, regx.password)
    const [isPwCompare, comparePwEvent] = useCompareCheck(pwRef, pwConfirmRef)

    const [isSend, sendResult, sendEvent] = useMessageRequest(contactRef)   // 문자 전송 Hook
    const [isConfirm, confirmResult, contactConfirmEvent] = useContactConfirmRequest(contactRef, contactConfirmRef)   // 문자 인증 Hook

    const [changePwEvent] = useChangePwRequest(idRef, pwRef, pwConfirmRef, confirmResult?.data?.authToken, isConfirm)   // 비밀번호 찾기 Hook

    return (
        <Style.WrapperDiv>

            <Style.Title>비밀번호 찾기</Style.Title>

            <Style.Form>
                <InputLabel value={"아이디"} placeholder={"아이디를 입력해주세요"} inputRef={idRef} required
                    blurEvent={checkIdEvent} isNormal={isIdNormal} exceptionMsg={"20자 이하의 값을 입력하세요."} />
                <InputLabel
                    value={"연락처"} placeholder={"010-1234-1234"} inputRef={contactRef} required
                    blurEvent={checkContactEvent} isNormal={isContactNormal} exceptionMsg={"올바른 연락처 형태로 입력해주세요. ( 010-xxxx-xxxx )"}
                    btnValue={"인증"} clickEvent={sendEvent} isPass={isSend} passMsg={"문자가 전송되었습니다."} />

                {isSend && <InputLabel
                    value={"인증번호"} placeholder={"xxxxxx"} inputRef={contactConfirmRef} required
                    blurEvent={checkContactConfirmEvent} isNormal={isContactConfirmNormal} exceptionMsg={"6자리의 값을 입력하세요."}
                    btnValue={"확인"} clickEvent={() => contactConfirmEvent(sendResult?.data?.id)} isPass={isConfirm} passMsg={"인증되었습니다."} />}

                {isConfirm && <>
                    <InputLabel value={"새 비밀번호"} type={"password"} placeholder={"비밀번호를 입력해주세요"} inputRef={pwRef} required
                        blurEvent={checkPwEvent} isNormal={isPwNormal} exceptionMsg={"20자 이하, 영문과 숫자와 특수문자를 포함해 입력해주세요."} />
                    <InputLabel value={"비밀번호 확인"} type={"password"} placeholder={"비밀번호를 확인해주세요"} inputRef={pwConfirmRef} required
                        blurEvent={comparePwEvent} isNormal={isPwCompare} exceptionMsg={"비밀번호와 동일한 값을 입력해주세요."} />
                    <Style.ButtonDiv>
                        <Button clickEvent={changePwEvent}>변경하기</Button>
                    </Style.ButtonDiv>
                </>}

            </Style.Form>



        </Style.WrapperDiv>
    )
}

export default PwFind