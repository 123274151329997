import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import useFetch from "shared/model/useFetch"
import useTextModalAtom from "shared/model/useTextModalAtom"
import regx from "shared/constant/regx"

const usePostConsulting = (idx, dateRef, timeRef) => {
    const navigate = useNavigate()
    const [backendState, setBackendState] = useFetch()
    const [, setModal] = useTextModalAtom()

    const exception = (date, time) => {
        if (!date.match(regx.date)) {
            setModal("상담 날짜 입력 조건을 확인해주세요.")
            return false
        }
        if (!time.match(regx.time)) {
            setModal("상담 시간 입력 조건을 확인해주세요.")
            return false
        }
        return true
    }

    const clickEvent = () => {
        const date = dateRef?.current?.value
        const time = timeRef?.current?.value

        if (exception(date, time)) {
            console.log(idx, date, time)
            setBackendState("POST", `/consults`, {
                "propertyId": idx,
                "hopefulDate": date,
                "hopefulTime": `${time}:00`
            })
        }
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                setModal("상담 신청이 완료되었습니다.\n빠른 시일 내에 담당자가 연락드리겠습니다.")
                navigate("/")
                break
            case 404:
                setModal("존재하지 않는 매물 입니다.")
        }
    }, [backendState])

    return [clickEvent]
}

export default usePostConsulting