import styled, { css } from "styled-components"

export default {
    "Wrapper": styled.div`
        display: flex;
        flex-direction: column;
        gap: 12px;
    `,
    "InputLabel": styled.p`
        ${({ theme }) => theme.font.small_bold};
        span {
            color: ${({ theme }) => theme.color.major};
        }
    `,

    "ExceptionDiv": styled.div`
        display: flex;
        flex-direction: column;
        gap: 4px;
    `,
    "InputDiv": styled.div`
        display: flex;
        align-items: center;
        height: 48px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.color.white};
        border: 1px solid ${({ theme }) => theme.color.dark_grey};
        ${({ $exception }) => $exception === false && css`
            border: 1px solid ${({ theme }) => theme.color.caution};
        `}
        /* false와 비교하는 것 수정하면 안됨 ( undefined와 차별점을 두기 위해 ) */
    `,
    "Exception": styled.p`
        ${({ theme }) => theme.font.extra_small};
        color: ${({ theme }) => theme.color.caution};
    `,
    "Certification": styled.p`
        ${({ theme }) => theme.font.extra_small};
        color: ${({ theme }) => theme.color.pass};
    `,
    "Input": styled.input`
        height: 100%;
        ${({ theme }) => theme.font.small};
        flex-grow: 1;
        padding: 0 16px;
        border-radius: 4px;
    `,

    "SelectDiv": styled.div`
        display: flex;
        height: 48px;
        background-color: ${({ theme }) => theme.color.white};
    `,
    "Select": styled.select`
        ${({ theme }) => theme.font.small};
        flex-grow: 1;
        padding: 0 16px;
        border: 1px solid ${({ theme }) => theme.color.dark_grey};
        border-radius: 4px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url(${({ src }) => src});
        background-position: right 16px center;
        background-repeat: no-repeat;
    `,

    "Button": styled.button`
        height: fit-content;
        margin-right: 16px;
        ${({ theme }) => theme.font.extra_small};
        background-color: ${({ theme }) => theme.color.major};
        color: ${({ theme }) => theme.color.white};
        border-radius: 16px;
        padding: 6px 16px;
    `
}