import { useRef } from "react"

import Style from "./style"
import useIsInputChange from "./model/useIsInputChange"

import useMyInfoRequest from "feature/account/useMyInfo"
import useUpdateInfoRequest from "feature/account/useUpdateMyInfo"
import useMessageRequest from "feature/message/usePostMessage"
import useContactConfirmRequest from "feature/message/usePostMessageConfirm"

import InputLabel from "shared/ui/LabelInput"
import Button from "shared/ui/Button"
import regx from "shared/constant/regx"
import useRegxCheck from "shared/model/useRegxCheck"
import useCompareCheck from "shared/model/useComapreCheck"

const Signup = () => {

    // Ref 모음
    const pwRef = useRef()
    const pwConfirmRef = useRef()
    const nameRef = useRef()
    const contactRef = useRef()
    const contactConfirmRef = useRef()
    const emailRef = useRef()

    // Input 예외처리 모음
    const [isPwNormal, checkPwEvent] = useRegxCheck(pwRef, regx.password)
    const [isPwCompare, comparePwEvent] = useCompareCheck(pwRef, pwConfirmRef)
    const [isNameNormal, checkNameEvent] = useRegxCheck(nameRef, regx.max10)
    const [isContactNormal, checkContactEvent] = useRegxCheck(contactRef, regx.contact)
    const [isChange, changeInputEvent] = useIsInputChange()
    const [isContactConfirmNormal, checkContactConfirmEvent] = useRegxCheck(contactConfirmRef, regx.auth_number)
    const [isEmailNormal, checkEmailEvent] = useRegxCheck(emailRef, regx.email)

    // Backend Request Hook 모음
    const [isSend, sendResult, sendEvent] = useMessageRequest(contactRef)   // 문자 전송 Hook
    const [isConfirm, , contactConfirmEvent] = useContactConfirmRequest(contactRef, contactConfirmRef)   // 문자 인증 Hook
    const [, myInfo] = useMyInfoRequest()   // 내 정보 가져오기 Hook
    const [updateInfoEvent] = useUpdateInfoRequest(
        pwRef, pwConfirmRef, nameRef, contactRef, emailRef,
        isChange, isConfirm
    )   // 내 정보 수정 Hook

    return (
        <Style.WrapperDiv>
            <Style.Title>내 정보 수정</Style.Title>
            <Style.Form>
                <InputLabel
                    value={"비밀번호"} type={"password"} placeholder={"비밀번호를 입력해주세요"} inputRef={pwRef} required
                    blurEvent={checkPwEvent} isNormal={isPwNormal} exceptionMsg={"20자 이하, 영문과 숫자와 특수문자를 포함해 입력해주세요."} />
                <InputLabel
                    value={"비밀번호 확인"} type={"password"} placeholder={"비밀번호를 확인해주세요"} inputRef={pwConfirmRef} required
                    blurEvent={comparePwEvent} isNormal={isPwCompare} exceptionMsg={"비밀번호와 동일한 값을 입력해주세요."} />
                <InputLabel
                    value={"이름"} initValue={myInfo?.data?.name} placeholder={"홍길동"} inputRef={nameRef} required
                    blurEvent={checkNameEvent} isNormal={isNameNormal} exceptionMsg={"10자 이하의 값을 입력하세요."} />
                <InputLabel
                    value={"연락처"} initValue={myInfo?.data?.phone} placeholder={"010-1234-1234"} inputRef={contactRef} changeEvent={changeInputEvent} required
                    blurEvent={checkContactEvent} isNormal={isContactNormal} exceptionMsg={"올바른 연락처 형태로 입력해주세요. ( 010-xxxx-xxxx )"}
                    {...(isChange && { btnValue: "인증", clickEvent: sendEvent, isPass: isSend, passMsg: "문자가 전송되었습니다." })}
                />
                {isSend && <InputLabel
                    value={"인증번호"} placeholder={"xxxxxx"} inputRef={contactConfirmRef} required
                    blurEvent={checkContactConfirmEvent} isNormal={isContactConfirmNormal} exceptionMsg={"6자리의 값을 입력하세요."}
                    btnValue={"확인"} clickEvent={() => contactConfirmEvent(sendResult?.data?.id)} isPass={isConfirm} passMsg={"인증되었습니다."} />}
                <InputLabel
                    value={"이메일"} initValue={myInfo?.data?.email} placeholder={"email@commingbrand.com"} inputRef={emailRef} required
                    blurEvent={checkEmailEvent} isNormal={isEmailNormal} exceptionMsg={"올바른 이메일 형태로 입력해주세요. ( xxxx@xxxx.xxx )"} />
            </Style.Form>
            <Style.ButtonDiv>
                <Button clickEvent={updateInfoEvent}>저장</Button>
            </Style.ButtonDiv>
        </Style.WrapperDiv>
    )
}

export default Signup