import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import Style from "./style"
import Deactive from "./asset/deactive.svg"
import Active from "./asset/active.svg"

import Button from "shared/ui/Button"
import useTextModalAtom from "shared/model/useTextModalAtom"

const SignupPre = () => {
    const navigate = useNavigate()
    const [allAgree, setAllAgree] = useState(false)
    const [privacyAgree, setPrivacyAgree] = useState(false)
    const [serviceAgree, setServiceAgree] = useState(false)
    const [marketingAgree, setMarketingAgree] = useState(false)

    const [, setTextModal] = useTextModalAtom()

    useEffect(() => {
        if (privacyAgree && serviceAgree && marketingAgree) setAllAgree(true)
        else setAllAgree(false)
    })

    const changeAllAgreeEvent = () => {
        setAllAgree(!allAgree)
        setPrivacyAgree(!allAgree)
        setServiceAgree(!allAgree)
        setMarketingAgree(!allAgree)
    }

    const movePageEvent = () => {
        if (allAgree) navigate("/signup")
        else setTextModal("동의를 진행해주세요.")
    }

    return (
        <Style.WrapperDiv>
            <Style.InputDiv>
                <Style.Check onClick={changeAllAgreeEvent}>
                    <img src={allAgree ? Active : Deactive} />
                    <Style.Label>전체 동의</Style.Label>
                </Style.Check>
            </Style.InputDiv>
            <Style.Form>
                <Style.InputDiv>
                    <Style.Check onClick={() => setPrivacyAgree(!privacyAgree)}>
                        <img src={privacyAgree ? Active : Deactive} />
                        <Style.Label>개인정보처리방침</Style.Label>
                    </Style.Check>
                    <Style.Link onClick={() => navigate("/term/privacy")}>자세히 보기</Style.Link>
                </Style.InputDiv>
                <Style.InputDiv>
                    <Style.Check onClick={() => setServiceAgree(!serviceAgree)}>
                        <img src={serviceAgree ? Active : Deactive} />
                        <Style.Label>서비스이용약관</Style.Label>
                    </Style.Check>
                    <Style.Link onClick={() => navigate("/term/service")}>자세히 보기</Style.Link>
                </Style.InputDiv>
                <Style.InputDiv>
                    <Style.Check onClick={() => setMarketingAgree(!marketingAgree)}>
                        <img src={marketingAgree ? Active : Deactive} />
                        <Style.Label>마케팅수신동의</Style.Label>
                    </Style.Check>
                    <Style.Link onClick={() => navigate("/term/marketing")}>자세히 보기</Style.Link>
                </Style.InputDiv>
            </Style.Form>
            <Button clickEvent={movePageEvent}>다음</Button>
        </Style.WrapperDiv>
    )
}

export default SignupPre