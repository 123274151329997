import styled from "styled-components"

export default {
    "WrapperDiv": styled.div`
        width: 90%;
        max-width: 1400px;
        padding: clamp(40px, 15vw, 120px) 0;
        display: flex;
        flex-direction: column;
    `,

    // ===== Title =====

    "Title": styled.h1`
        ${({ theme }) => theme.font.extra_large_bold};
        span {
            ${({ theme }) => theme.font.extra_large_bold};
            color: ${({ theme }) => theme.color.major};
        }
    `,

    // ===== Info =====

    "InfoDiv": styled.div`
        display: flex;
        align-items: center;
        background-color: ${({ theme }) => theme.color.light_major};
        border-radius: 8px;
        padding: 64px;
        margin: 56px 0;
        gap: clamp(40px, 10vw, 80px);

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                flex-direction: column;
                padding: 10%;
            }
        }
    `,
    "InfoImg": styled.img`
        height: 254px;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                width: 80%;
            }
        }
    `,
    "InfoList": styled.div`
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 12px;
    `,
    "InfoTitle": styled.h2`
        ${({ theme }) => theme.font.large_bold};
        padding-bottom: 8px;
        word-wrap: break-word;
    `,
    "Info": styled.div`
        display: flex;
        gap: 24px;
    `,
    "InfoLabel": styled.h2`
        ${({ theme }) => theme.font.small_bold};
    `,
    "InfoValue": styled.p`
        ${({ theme }) => theme.font.small};
    `,

    // ===== Chapter =====

    "ChapterDiv": styled.div`
        display: flex;
        flex-direction: column;
        gap: 32px;
    `,
    "ChapterTitleBox": styled.div`
        display: flex;
        align-items: center;
        gap: 8px;
        padding-bottom: 12px;
        border-bottom: 1px solid ${({ theme }) => theme.color.black};
    `,
    "ChapterTitle": styled.h2`
        ${({ theme }) => theme.font.small_bold};
    `,
    "ChapterTitleValue": styled.h2`
        ${({ theme }) => theme.font.small};
        color: ${({ theme }) => theme.color.placeholder};
        word-wrap: break-word;
    `,
    "Chpater": styled.div`  
        display: flex;
        background-color: ${({ theme }) => theme.color.white};
        padding: 24px 32px;
        border-radius: 8px;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                flex-direction: column;
            }
        }
    `,
    "ChapterInfoDiv": styled.div`
        flex-grow: 1;
        padding-right: 32px;
    `,
    "ChapterTitle": styled.h1`
        ${({ theme }) => theme.font.medium_bold};
    `,
    "ChapterElemList": styled.div`
        display: flex;
        flex-wrap: wrap;
        column-gap: 40px;
        row-gap: 4px;
        margin-top: 16px;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                flex-direction: column;
            }
        }
    `,
    "ChapterElemDiv": styled.div`
        width: fit-content;
        display: flex;
        gap: 12px;
    `,
    "ChapterElemLabel": styled.h2`
        ${({ theme }) => theme.font.small_bold};
        white-space: nowrap;
    `,
    "ChapterElemValue": styled.p`
        ${({ theme }) => theme.font.small};
        white-space: nowrap;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                white-space: normal;
                word-wrap: break-word;
            }
        }
    `,
    "ChapterControlDiv": styled.div`
        display: flex;
        justify-content: end;
        align-items: center;
        width: fit-content;
        padding-left: 32px;
        border-left: 1px solid ${({ theme }) => theme.color.placeholder};

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                width: 100%;
                border: none;
                padding: 0;
                margin-top: 24px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    `,
    "ChapterDetailBtn": styled.button`
        ${({ theme }) => theme.font.extra_small_bold};
        color: ${({ theme }) => theme.color.white};
        background-color: ${({ theme }) => theme.color.major};
        padding: 8px 28px;
        border-radius: 16px;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                width: 100%;
                padding: clamp(8px, 1.5vw, 12px) 0;
            }
        }
    `
}