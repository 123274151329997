import { useNavigate } from "react-router-dom"

import Style from "./style"
import logo from "./asset/logo.png"

import useAuthCookie from "shared/model/useAuthCookie"

const Header = () => {
    const navigate = useNavigate()
    const [authCookie, , deleteAuthCookie] = useAuthCookie()

    const logoutEvent = () => {
        deleteAuthCookie()
        navigate("/")
    }

    return (
        <Style.Wrapper>
            <Style.LogoImg src={logo} onClick={() => navigate("/")} />
            <Style.MenuWrapper>
                {authCookie
                    ? <>
                        <Style.MenuBtn onClick={logoutEvent}>로그아웃</Style.MenuBtn>
                        <Style.MenuBtn onClick={() => navigate("/mypage")}>마이페이지</Style.MenuBtn>
                    </>
                    : <>
                        <Style.MenuBtn onClick={() => navigate("/login")}>로그인</Style.MenuBtn>
                        <Style.MenuBtn onClick={() => navigate("/signup-pre")}>회원가입</Style.MenuBtn>
                    </>
                }
                <Style.MenuBtn onClick={() => navigate("/matching")}>상권찾기</Style.MenuBtn>
            </Style.MenuWrapper>
        </Style.Wrapper>
    )
}

export default Header