import styled from "styled-components"

export default {
    "WrapperDiv": styled.main`
        width: 90%;
        max-width: 616px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: clamp(48px, 20vw, 160px) 0;
        padding: clamp(40px, 10vw, 80px) 96px;
        background-color: ${({ theme }) => theme.color.white};
        border-radius: 16px;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                padding-left: 5%;
                padding-right: 5%;
            }
        }
    `,
    "InputDiv": styled.div`
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    `,
    "Form": styled.div`
        display: flex;
        flex-direction: column;
        gap: clamp(16px, 4vw, 32px);
        margin: clamp(24px, 6vw, 48px) 0 clamp(32px, 8vw, 64px) 0;
    `,
    "Check": styled.button`
        display: flex;
        align-items: center;
    `,
    "Label": styled.p`
        ${({ theme }) => theme.font.small_bold};
        padding-left: 16px;
    `,
    "Link": styled.button`
        ${({ theme }) => theme.font.small};
        border-bottom: 1px solid ${({ theme }) => theme.color.black};
    `
}