const privacy = `제 1조 (목적)

본 이용약관은 (주)이지스비즈가 운영하는 커밍브랜드 서비스 이용권한을 부여받기 위한 조건을 명시하며 이용자의 권리, 의무 및 책임사항에 대한 동의를 목적으로 합니다. 커밍브랜드의 이용자는 본 약관에 동의해야 서비스를 이용할 수 있습니다.


제 2조 (정의)
 
1. 약관에서 명시하는 이용자란 당사가 제공하는 유·무료 서비스를 이용하는 회원·비회원을 통칭합니다.
2. 회원이란 본 약관에 동의하고 계정을 생성한 자로서 당사가 제공하는 정보와 서비스를 지속적으로 이용할 수 있는 자를 말합니다.
3. 비회원이란 회원에 가입하지 않고 당사가 제공하는 정보와 서비스를 이용하는 자를 말합니다.
 

제 3조 (약관에 대한 명시 및 변경)
 
1. 당사는 필요에 따라 약관을 수정할 수 있으며, 개정 전 약관과 함께 적용일자 7일 전부터 웹 사이트에서 확인할 수 있도록 게시합니다.
2. 기존 이용자에게 변경 약관에 대한 내용을 가입 시 기재한 전자우편 주소로 발송합니다.
3. 회원은 변경된 약관에 동의하지 않을 경우 탈퇴(해지)를 할 수 있는 한편 변경된 약관의 효력 발생일 이후 서비스를 계속 이용할 경우 약관의 변경 사항에 동의한 것으로 간주 됩니다.
4. 본 약관에 명시하지 않은 사항과 본 약관의 해석에 관해는 정부가 제정한 소비자 보호지침 및 관계 법령 또는 관례에 따릅니다.
 

제 4조 (당사정보 등의 제공)
 
1. 당사는 다음의 각 사항을 홈페이지에 게시, 이용자가 이를 쉽게 알 수 있도록 합니다.
 
- 상호 및 대표자의 성명
- 영업소 소재지 주소
- 전자우편주소 및 전화번호
- 사업자등록번호 및 통신 판매업 신고번호
- 개인정보 취급 방침
- 서비스 이용약관
 

제 5조 (회원가입)
 
1. 회원으로서 서비스의 이용을 희망하는 자는 약관에 동의하고, 당사가 제시하는 회원가입 양식에 주요 사항을 기재해야 합니다.
2. 회원가입 시 연락 가능한 전자우편주소를 기재해야 합니다. 이용자의 실수 또는 의도적으로 유효하지 않은 전자우편 주소를 기재함으로써 당사의 정보 및 서비스 이용과 관련된 정보가 수신되지 않을 경우 이에 대한 책임은 이용자에게 있습니다.
3. 만 14세 미만의 경우에는 부모님 등 법정 대리인의 동의가 있는 경우에만 계정을 생성 할 수 있습니다
 

제6조 (회원의 해제)
 
1. 회원은 언제든 서비스 이용을 중단하고 이용계약을 해지할 수 있습니다.
2. 회원이 회원탈퇴를 원하는 경우 홈페이지 내 절차를 통해 회원탈퇴를 할 수 있습니다.
3. 회원이 회원탈퇴를 할 경우 계정에 속한 모든 이용권한과 구독권을 포함한 재산가치는 즉시 소멸하며 복구되지 않습니다. 이는 계정공유 및 계정도용으로 인한 회원탈퇴에도 적용됩니다.
 

제 7조 (회원 자격상실 및 이의제기)
 
1. 당사는 회원이 서비스 이용 내용에 있어 다음 각 호에 해당하는 경우 사전통보 없이 서비스 이용을 제한하거나 회원 자격을 중지시킬 수 있습니다.
 
-서비스 제공을 방해하거나 부정한 방법으로 서비스를 사용하는 행위
-다른 이용자의 개인정보를 무단으로 수집 및 제공하는 행위
-당사의 동의를 받지 않고 이것을 영리적 목적으로 이용하는 행위
-음란정보나 저작권 침해 등 불법행위를 발송 또는 게시하는 행위
-당사가 제공하는 소프트웨어의 일부를 복사, 수정, 배포, 판매, 대여, 역설계, 추출 등 서비스를 변형하려는 일체의 행위
-법령, 법원 판결, 결정 혹은 명령 또는 법령상 구속력을 가지는 행정 조치에 위반되는 행위
-공공질서 또는 미풍양속을 저해할 우려가 있는 행위
-당사 또는 제 3자의 저작권, 상표권, 지적재산권, 명예권, 프라이버시권 등 재산상 권리를 침해하는 행위
-비속어, 욕설, 약자비하, 인신공격, 남녀차별, 지역감정 등 국민정서에 반하는 일체의 행위
-당사 혹은 제 3자를 사칭하는 행위 또는 의도적으로 허위 정보를 유포하는 행위
-같은 메시지를 다른 이용자 편의성을 저해하면서 지나치게 반복 전송하는 등 스팸이라 판단되는 행위
-다른 이용자에 대한 악의적인 비방 및 중상모략 등을 목적으로 하는 행위
-반사회적인 세력에 대한 협력 행위
-특정 종교활동을 권유하는 행위
-타인의 개인정보, 이용이력 등을 불법으로 수집, 공개 또는 제공하는 행위
-당사가 정한 이용 목적과 다른 목적으로 서비스를 이용하는 행위
-성인 콘텐츠 이용 시 청소년 보호법 등에 따른 등급 및 연령 준수를 위반하는 행위
-타인의 결제수단 및 메일주소를 허락없이 도용하거나 사용하는 행위
-본 약관을 포함해 기타 규정 또는 이용조건을 위반하는 행위
 
2. 서비스 이용 제한은 일시정지, 초기화, 영구정지, 이용계약 해지 등으로 구분됩니다.
3. 당사는 이용 제한을 하는 경우에 회원에게 가입 시 기재한 전자우편을 통해 회원에게 내용을 통보합니다.
4. 회원이 당사의 이용 제한에 불복하고자 할 때에는 통보를 받은 날로부터 15일 이내에 당사의 이용 제한에 불복하는 이유를 기재한 이의 신청서를 당사가 정한 방법으로 제출해야 합니다.
5. 이의 신청서를 접수한 당사는 접수한 날로부터 15일 이내에 회원의 불복 이유에 대해 전자우편으로 답변합니다. 다만 당사는 15일 이내에 답변이 곤란한 경우 회원에게 그 사유와 처리 일정을 통보합니다.
6. 당사는 위 답변 내용에 따라 상응하는 조치를 취해야 합니다.
 

제 8조 (개인정보의 보호 및 관리)
 
1. 당사는 관련 법령이 정하는 바에 따라 계정정보를 포함한 회원의 개인정보를 보호하기 위해 노력합니다. 회원 개인정보의 보호 및 사용에 대해서는 관련 법령 및 당사가 별도로 고지하는 개인정보 취급방침이 적용됩니다.
2. 웹사이트에 단순히 링크된 제 3자 제공의 서비스에 대해서는 당사의 개인정보 취급방침이 적용되지 않습니다.
3. 당사는 회원의 귀책사유로 인해 노출된 회원의 계정정보를 포함한 모든 정보에 대해서 일체의 책임을 지지 않습니다.
 

제 9조 (계정 관리)
 
1. 기본적으로 계정 또는 로그인 정보를 본인 외의 제 3자에게 공유하거나 양도할 수 없으며 이 경우 문제가 발생했을 때 당사는 책임을 지지 않습니다.
2. 회원은 자신의 계정정보를 비밀로 유지할 책임이 있으며, 로그인 정보사용에 대한 책임을 집니다.
3. 마지막 접속 시점으로부터 1년 이상 경과한 계정은 이용자에게 통지없이 삭제될 수 있습니다.
 

제 10조 (계정 보안)
 
1. 로그인 정보의 분실, 도난 또는 허가없는 유출 등 보안에 대한 위반사실을 발견하거나 의심이 가는 경우, 이용자는 즉시 당사에 알려줘야 합니다.
2. 계정 관리의 소홀 또는 계정 공유로 인해 발생하는 손해에 대해서는 당사가 책임을 지지 않습니다.
 

제 11조 (주문 및 결제)
 
1. 회원은 당사가 제공하는 절차에 의해 재화 및 콘텐츠 구매 및 결제가 가능합니다.
2. 미성년 회원의 결제는 원칙적으로 보호자(법정대리인)의 명의 또는 동의 아래 이뤄져야 하고, 보호자는 본인 동의없이 체결된 피보호자의 계약을 취소할 수 있습니다.
 

제 12조 (결제취소 및 환불)
 
1. 결제가 이뤄지자마자 콘텐츠가 노출되고 재화의 가치가 급격히 훼손된다는 측면에서 기본적으로 불가능하나 연 결제 시 요청시점 당월 기준으로 월간 사용료 차감 후 환불합니다. 
2. 당사는 '전자상거래 등에서의 소비자보호에 관한 법률'에 따라 그 사실을 쉽게 알 수 있는 곳에 명확하게 표시하거나 샘플 콘텐츠 무료제공을 통해 서비스 품질을 미리 확인할 수 있도록 조치합니다. 
3. 계약종료로 판매가 중단된 콘텐츠의 경우 이전 구매내역에 대한 환불이 불가능합니다.
4. 이용자가 약관을 위반해서 계정이 정지된 경우 환불이 불가능합니다.
5. 유료계정 휴면 및 이용기간 변경은 불가능합니다.
6. 환불에 대한 신청은 전자우편을 통해 접수할 수 있습니다.
 
- ceo@commingbrand.com
 

제 13조 (서비스 이용의 기술적 요구 사항)
 
1. 당사는 서비스를 공급하면서 아래의 필수사양이 충족되어야 정상적인 서비스 이용이 가능하며, 이용자가 해당 사양에 충족되지 못한 사유로 서비스 이용에 이의를 제기할 시 당사는 책임을 지지 않습니다.
 
- 컴퓨터 운영체제(OS) : Windows 등 웹브라우저 이용이 가능한 OS
- 웹브라우저 : Internet Explorer 9.0 이상, Chrome , Safari , Firefox 
- 모바일 단말기 : 안드로이드, 아이폰, 아이패드, 갤럭시탭
- 서비스 접속 시 발생되는 인터넷 연결 비용은 이용자가 부담 합니다.
 

제 14조 (콘텐츠 이용 및 권한)
 
1. 당사가 제공하는 모든 콘텐츠는 기본적으로 연령대 제한이 없으나 회원등급에 따라 접근이 달리 합니다.
2. 이용자는 정해진 열람권한을 넘어 콘텐츠 복제, 송신, 전재, 수정 등의 행위를 하면 안됩니다.
 

제 15조 (업데이트)
 
1. 서비스 이용을 위해 새로운 소프트웨어가 필요하거나 추가되는 경우, 이용자의 동의에 의해 기기에서 소프트웨어를 자동으로 업데이트하거나 설치할 것을 요구할 수 있습니다.
2. 당사는 서비스를 지속적으로 변경 및 개선 할 수 있고, 기능을 추가 또는 제거 할 수 있습니다.
 

제 16조 (서비스 중단)
 
1. 당사는 당사의 영업방침에 따라 정해진 시간동안 서비스를 제공하되 다음과 같은 경우 예외사례를 둡니다.
 
- 컴퓨터 및 서버 등 정보통신설비의 보수점검, 교체, 정기점검하는 경우
- 사이트 및 서비스 개편하는 경우
- 통신사고, 보안사고 등 서비스 불안정성에 대응하기 위해 필요한 경우
- 천재지변, 비상사태, 정전, 설비장애, 이용자 폭주 등 정상적인 서비스 제공이 불가능한 경우
- 정부 주무부처로부터 통제를 받은 경우
- 당사의 분할, 합병, 영업양도, 영업의 폐지, 당 해 당사의 수익 약화 등 경영상 중대한 필요에 의한 경우
 
2. 위의 이유로 인해 당사는 서비스를 중단할 수 있으며, 30일 전에 홈페이지에 이를 공지합니다. 사전에 통지할 수 없는 부득이한 사정이 있는 경우는 사후에 통지를 할 수 있습니다.
 

제 17조 (손해배상)
 
1. 당사는 서비스 이용과 관련해 회원에게 발생한 손해에 대해 당사의 고의 또는 과실이 있는 경우 책임을 지도록 하겠습니다.
2. 당사는 사전공지 없이 당사의 고의 또는 과실로 인해 발생하는 서비스 중단이 6시간 이상 연속적으로 발생하면 중단시간의 2배수로 서비스시간을 부여합니다.
3. 당사는 경영상 이유로 사이트를 폐쇄하거나 서비스를 완전 종료했을 때 다음과 같이 환불합니다.
 
- 월 결제 시 전액환불
- 연 결제 시 폐쇄 및 종료시점 기준으로 전월까지 월간 사용료 차감 후 환불
 
4. 당사가 외부사업자와 제휴를 맺고 외부서비스를 제공했을 때 회원이 해당 이용약관에 동의했다면 해당 사업자가 문제발생 시 손해책임을 집니다.
5. 당사는 이용자에게 제공한 콘텐츠 내용이 표시, 광고된 내용과 다를 경우 당해 콘텐츠를 공급 받은 날로부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날로부터 30일 이내에 적절한 방법으로 손해배상을 합니다.
 

제 18조 (과오금)
 
1. 당사는 과오금이 발생한 경우 회원이 결제한 방법과 동일한 방법으로 환불하며, 동일한 방법으로 환불이 불가능할 때에는 이를 사전에 고지합니다.
2. 당사의 책임이 있는 사유로 과오금이 발생한 경우에는 과오금 전액을 환불하며, 회원의 책임이 있는 사유로 과오금이 발생한 경우 합리적인 범위 내에서 양측이 부담해야 합니다.
3. 당사는 과오금의 환불절차를 콘텐츠 이용자보호지침에 따라 처리 합니다.
 

제 19조 (면책 사항)
 
1. 당사는 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인해 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.
2. 당사는 기간통신사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 않아 손해가 발생한 경우 책임이 면제됩니다.
3. 당사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대해 책임이 면제됩니다.
4. 당사는 회원의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대해 책임을 지지 않습니다.
5. 당사는 이용자의 PC 및 휴대기기 오류에 의해 손해가 발생한 경우, 또는 회원이 전자우편 주소를 부실하게 기재해 손해가 발생한 경우 책임을 지지 않습니다.
6. 당사는 회원이 서비스를 이용하면서 얻은 자료로 인한 손해, 그리고 회원이 서비스를 이용하며 타 회원으로 인해 입게 되는 정신적 피해에 대해 보상할 책임을 지지 않습니다.
7. 당사는 서비스에 게재한 각종 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 대해 책임을 지지 않습니다.
8. 당사는 이용자 상호간 및 이용자와 제 3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이에 따른 손해를 배상할 책임도 없습니다.
 

제 20조 (분쟁해결)
 
1. 당사는 회원 및 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위해 관련 대책 및 조직을 운영·설치합니다.
2.  회원으로부터 제출되는 불만사항 및 의견은 우선으로 그 사항을 처리합니다. 다만 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
3. 회원 간에 발생한 전자상거래 분쟁과 관련해 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
4. 보도 분쟁에 관련해 이해당사자의 피해구제신청에 있는 경우에 언론중재우원회의 조정에 따를 수 있습니다.
 

제 21조 (재판권 및 준거법)
 
1. 당사와 회원간에 발생한 분쟁으로 소송이 제기되는 경우, 이용자의 주소지 관활법인(주소가 없는 경우에는 관련법 규정에 따릅니다.)을 전속관할로 합니다.
2. 본 약관과 당사와 이용자 간 제기된 전자상거래 소송에는 대한민국 법률을 적용합니다.
`

const service = `수집하는 개인정보의 형식
 
1. 커밍브랜드 이용을 위해 개인정보의 수집이 필요합니다. 수집되는 개인정보는 다음과 같으며 사용자 동의 후 수집됩니다.
 
- 계정 정보 : 이름, 전자우편 주소, 연락처
- 로그데이터: IP정보, 디바이스 및 브라우저 정보, 조회된 도메인, 방문 웹페이지, 이용통신사 구분, 이용기록, 불량 이용기록
 
개인정보 수집 방법
 
1. 사용자가 직접 입력하거나 이용과정에서 수집됩니다. 그리고 서비스 개선과 사용자 반응 파악을 위한 이용통계 수집툴에서 수집되기도 합니다.
2. 이용자가 보다 풍부한 이벤트와 서비스를 이용하시기 위한 목적 및 본 서비스를 원활하게 이용하도록 하기 위한 목적으로 사용됩니다.
 
개인정보 수집 및 이용목적
 
1. 커밍브랜드 원활한 이용을 위해 개인정보를 수집합니다. 세부적인 이용 목적은 다음과 같습니다.
 
- 본인확인과 부정이용 방지를 위해
- 서비스 이용문의 응대를 위해
- 이용 현황 파악을 위한 통계 데이터 축적을 위해
- 캠페인 이벤트 등 선물 발송을 위해
- 중요 공지사항의 전달을 위해
- 이벤트와 광고 전달을 위해
- 유료 콘텐츠 이용 시 전송, 배송, 요금 정산을 위해
- 새로운 콘텐츠 추천을 위해
 
개인정보의 공유 및 제공
 
1. 개인정보는 제3자에게 제공되지 않습니다. 하지만 커밍브랜드와 연계된 서비스 또는 결제와 같이 제3자의 응대가 필요한 경우, 동의를 통해 개인정보가 전달 될 수도 있습니다. 개인정보는 서비스 이용 완료 또는 고객 응대 후 파기됩니다.
 
이용자 및 법정대리인의 권리와 그 행사방법
 
1. 이용자 및 만 14세 미만의 사용자의 경우 법정 대리인은 개인정보의 조회, 수정 및 계정 삭제가 가능합니다. 
 
개인정보 자동 수집 장치의 설치 / 운영 및 거부에 관한 사항
 
1. 원활한 서비스 제공을 위해 쿠키를 생성해 사용자의 저장장치에 저장하기도 합니다. 쿠키는 이용자들의 사용 행태를 파악해 이용자들에게 최적화된 정보제공을 위해 사용합니다. 쿠키 설정에 대해서 사용 하시는 브라우저의 설정에서 쿠키의 확인, 저장, 거정 등을 설정 할 수 있고 저장 거부시 로그인 등에 어려움이 발생 할 수도 있습니다.
 
개인정보 보유 및 이용기간
 
이용 목적을 위해 한시적으로 보유하고 목적 달성 시 개인정보는 파기됩니다. 하지만 관계 법령 등으로 보존 필요가 있는 경우 일정 기간 보관합니다.
 
개인정보 파기절차 및 방법
 
종이에 인쇄된 경우 분쇄 또는 소각으로 파기하며 전자적 파일의 경우 기술적으로 파기됩니다.
 
개인정보 관리책임자
 
이름 : 권남홍
소속 : (주)이지스비즈
직위 : 대표
연락처 : 02-565-5735
email : ceo@commingbrand.com
 
개인정보취급방침의 개정 공고일자, 시행일자
 
1. 개인정보취급방침은 더욱 편리한 서비스 제공을 위해 변경 가능합니다.
2. 중요한 변경사항이 있을 때에는 알기 쉬운 방법으로 개정 전에 이용자에게 개정 내용을 공지합니다.
 
공고일자 : 2024년 6월 30일
`

const marketing = `(주)이지스비즈는 개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관계법령에 따라 광고성 정보를 전송하기 위해 수신자의 사전 수신동의를 받고 있으며 광고 성 정보 수신자의 수신동의 여부를 정기적으로 확인합니다.

1. 전송방법

휴대전화 문자메시지(SMS), Email, 카카오 알림톡 등을 통해 마케팅 정보를 전달할 수 있습니다.

2. 전송내용

발송되는 마케팅 정보는 수신자에게 신규 컨텐츠 및 신규 매물에 대한 광고성 정보로 관련 법의 규정을 준수하여 발송됩니다. 개인정보보호법 제 22조 제 4항에 의해 선택 정보사항을 기재하지 않아도 서비스를 이용할 수 있습니다.

3. 철회안내

수신 동의 이후에도 의사에 따라 동의를 철회할 수 있으며, 수신을 동의하지 않아도 (주)이지스비즈가 제공하는 기본적인 커밍브랜드 서비스를 이용할 수 있으나 당사의 마케팅 정보를 수신하지 못할 수 있습니다.

4. 수신동의 변경

카카오 알림톡 내의 설정 및 ceo@commingbrand.com 전자 우편을 통해 수신동의를 변경(동의/철회)할 수 있습니다.
`

export { privacy, service, marketing }