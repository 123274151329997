import { useState, useEffect } from "react"

import useFetch from "shared/model/useFetch"
import useTextModalAtom from "shared/model/useTextModalAtom"
import regx from "shared/constant/regx"

// ** Params
// 1. 연락처 input : useRef
// 2. 인증번호 input : useRef
const usePostMessageConfirm = (contactRef, confirmCodeRef) => {
    const [backendState, setBackendState] = useFetch()
    const [isConfirm, setIsConfirm] = useState(false)
    const [, setModal] = useTextModalAtom()

    const exception = (contact, confirmCode) => {
        if (!contact.match(regx.contact)) {
            setModal("연락처 값을 확인해주세요.")
            return false
        }
        if (!confirmCode.match(regx.auth_number)) {
            setModal("인증번호 값을 확인해주세요.")
            return false
        }
        return true
    }

    const clickEvent = (confirmIdx) => {
        const contact = contactRef?.current?.value
        const confirmCode = confirmCodeRef?.current?.value

        if (exception(contact, confirmCode)) {
            setBackendState("POST", `/ncp/number/verify`, {
                "id": confirmIdx,
                "authNumber": confirmCode,
                "phone": contact
            })
        }
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                setIsConfirm(true)
                break
            case 400:
                setModal("인증번호가 잘못되었거나 \n인증 제한시간이 초과하였습니다.")
                setIsConfirm(false)
                break
        }
    }, [backendState])

    return [isConfirm, backendState, clickEvent]
}

export default usePostMessageConfirm