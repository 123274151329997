import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import useFetch from "shared/model/useFetch"
import useTextModalAtom from "shared/model/useTextModalAtom"
import regx from "shared/constant/regx"

const usePutPw = (idRef, pwRef, pwConfirmRef, confirmToken, ...certificatedList) => {
    const navigate = useNavigate()
    const [backendState, setBackendState] = useFetch()
    const [, setModal] = useTextModalAtom()

    const exception = (id, pw, pwConfirm, checkList) => {
        if (!id.match(regx.max20)) {
            setModal("아이디 값을 확인해주세요.")
            return false
        }
        if (!pw.match(regx.password)) {
            setModal("비밀번호 값을 확인해주세요.")
            return false
        }
        if (pw !== pwConfirm) {
            setModal("비밀번호가 일치하는지 확인해주세요.")
            return false
        }

        // 연락처 인증 여부
        if (checkList.includes(false)) {
            setModal("모든 인증을 진행해주세요.")
            return false
        }

        return true
    }

    const clickEvent = () => {
        const id = idRef?.current?.value
        const pw = pwRef?.current?.value
        const pwConfirm = pwConfirmRef?.current?.value
        const token = confirmToken

        if (exception(id, pw, pwConfirm, certificatedList)) {
            setBackendState("POST", "/account/password/edit", {
                "password": pw,
                "account": id,
                "authToken": token
            })
        }
    }

    useEffect(() => {
        switch (backendState?.code) {
            case 200:
                setModal("비밀번호가 변경되었습니다.\n다시 로그인해주세요.")
                navigate("/login")
                break
            case 400:
                setModal("입력 값이 잘못되었거나 \n인증 제한시간이 초과하였습니다.")
                break
            case 404:
                setModal("입력하신 정보에 대한 계정이 존재하지 않습니다.")
                break
        }
    }, [backendState])

    return [clickEvent]
}

export default usePutPw