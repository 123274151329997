import styled, { css } from "styled-components"

export default {
    "Wrapper": styled.div`
        display: flex;
        flex-direction: column;
        gap: 12px;
    `,
    "InputLabel": styled.p`
        ${({ theme }) => theme.font.small_bold};
        span {
            color: ${({ theme }) => theme.color.major};
        }
    `,

    "ExceptionDiv": styled.div`
        display: flex;
        flex-direction: column;
        gap: 4px;
    `,
    "InputDiv": styled.div`
        display: flex;
        justify-content: space-between;
        height: 48px;
        gap: 8px;
    `,
    "Elem": styled.div`
        display: flex;
        align-items: center;
        height: 100%;
        gap: 4px;
    `,
    "Input": styled.input`
        width: 90%;
        height: 100%;
        ${({ theme }) => theme.font.small};
        padding: 0 16px;
        border: 1px solid ${({ theme }) => theme.color.dark_grey};
        border-radius: 4px;
        background-color: ${({ theme }) => theme.color.white};
        ${({ $exception }) => $exception === false && css`
            border: 1px solid ${({ theme }) => theme.color.caution};
        `}
    `,
    "Exception": styled.p`
        ${({ theme }) => theme.font.extra_small};
        color: ${({ theme }) => theme.color.caution};
    `,
    "Unit": styled.p`
        ${({ theme }) => theme.font.small};
        white-space: nowrap;
    `
}