// 1. 타임스탬프 : timestamp
// 2. 옵션 : string ("all" || "date")
const convertTimestamp = (timestamp, option = "all") => {

    const utcDate = new Date(timestamp)
    utcDate.setHours(utcDate.getHours() + 9)

    if (option === "all")
        return utcDate.toLocaleString()
    else if (option === "date")
        return utcDate.toLocaleDateString()

    return ""
}

export default convertTimestamp