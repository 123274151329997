import { useRef } from "react"

import Style from "./style"
import useFindIdRequest from "../../feature/account/useFindId"

import useMessageRequest from "feature/message/usePostMessage"
import useContactConfirmRequest from "feature/message/usePostMessageConfirm"
import InputLabel from "shared/ui/LabelInput"
import Button from "shared/ui/Button"
import regx from "shared/constant/regx"
import useRegxCheck from "shared/model/useRegxCheck"

const IdFind = () => {
    const contactRef = useRef()
    const contactConfirmRef = useRef()

    const [isContactNormal, checkContactEvent] = useRegxCheck(contactRef, regx.contact)
    const [isContactConfirmNormal, checkContactConfirmEvent] = useRegxCheck(contactConfirmRef, regx.auth_number)

    const [isSend, sendResult, sendEvent] = useMessageRequest(contactRef)   // 문자 전송 Hook
    const [isConfirm, confirmResult, contactConfirmEvent] = useContactConfirmRequest(contactRef, contactConfirmRef)   // 문자 인증 Hook

    const [findIdResult, getFindIdEvent] = useFindIdRequest(confirmResult?.data?.authToken, isConfirm)   // 아이디 찾기 Hook

    return (
        <Style.WrapperDiv>

            <Style.Title>아이디 찾기</Style.Title>

            <Style.Form>
                <InputLabel
                    value={"연락처"} placeholder={"010-1234-1234"} inputRef={contactRef} required
                    blurEvent={checkContactEvent} isNormal={isContactNormal} exceptionMsg={"올바른 연락처 형태로 입력해주세요. ( 010-xxxx-xxxx )"}
                    btnValue={"인증"} clickEvent={sendEvent} isPass={isSend} passMsg={"문자가 전송되었습니다."} />
                {isSend && <InputLabel
                    value={"인증번호"} placeholder={"xxxxxx"} inputRef={contactConfirmRef} required
                    blurEvent={checkContactConfirmEvent} isNormal={isContactConfirmNormal} exceptionMsg={"6자리의 값을 입력하세요."}
                    btnValue={"확인"} clickEvent={() => contactConfirmEvent(sendResult?.data?.id)} isPass={isConfirm} passMsg={"인증되었습니다."} />}
            </Style.Form>

            <Style.ButtonDiv>
                <Button clickEvent={getFindIdEvent}>찾기</Button>
            </Style.ButtonDiv>

            {findIdResult && <>
                <Style.Hr />
                <Style.ResultTile>연락처로 찾은 회원님의 아이디 입니다.</Style.ResultTile>
                <Style.ResultValue>{findIdResult?.data?.account}</Style.ResultValue>
            </>}

        </Style.WrapperDiv>
    )
}

export default IdFind