import { atom, useResetRecoilState } from "recoil"
import { useRecoilState } from "recoil"

const useTextModalAtom = () => {

    const textModalAtom = atom({
        "key": "textModalAtom",
        "default": null
    })

    const [state, setState] = useRecoilState(textModalAtom)
    const resetState = useResetRecoilState(textModalAtom)

    const setModalEvent = (label) => {
        setState(label)
    }

    return [state, setModalEvent, resetState]
}

export default useTextModalAtom