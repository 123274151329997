import styled, { css } from "styled-components"

export default {
    "WrapperDiv": styled.div`
        width: 90%;
        max-width: 1000px;
        padding: clamp(40px, 15vw, 120px) 0;
        display: flex;
        flex-direction: column;
    `,
    "Title": styled.h2`
        ${({ theme }) => theme.font.medium_bold};
    `,

    "Table": styled.div`
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid ${({ theme }) => theme.color.black};
        margin-top: 32px;
    `,
    "Elem": styled.div`
        height: 56px;
        display: flex;
        flex-basis: 50%;
        flex-grow: 1;
        border-top: 1px solid ${({ theme }) => theme.color.black};

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                flex-basis: 100%;
            }
        }
    `,
    "ElemLabelDiv": styled.div`
        display: flex;
        align-items: center;
        padding-left: 24px;
        max-width: 136px;
        background-color: ${({ theme }) => theme.color.light_major};
    `,
    "ElemLabel": styled.h2`
        ${({ theme }) => theme.font.small_bold};
    `,
    "ElemValueDiv": styled.div`
        display: flex;
        align-items: center;
        flex-grow: 1;
        background-color: ${({ theme }) => theme.color.white};
    `,
    "ElemValue": styled.p`
        ${({ theme }) => theme.font.small};
        padding-left: 24px;
        ${({ $type }) => $type === "hide" && css`
            color: ${({ theme }) => theme.color.placeholder};
        `}
    `,

    "ButtonDiv": styled.div`
        width: 100%;
        max-width: 400px;
        margin-top: clamp(32px, 8vw, 64px);
        display: flex;
        margin-left: auto;

        @media only screen and (max-width: ${({ theme }) => theme.windowSize.mobile}) {
            & {
                margin-right: auto;
                justify-content: center;
            }
        }
    `,
    "Button": styled.button`
        padding: 12px 120px;
        ${({ theme }) => theme.font.small_bold};
        background-color: ${({ theme }) => theme.color.major};
        color: ${({ theme }) => theme.color.white};
        border-radius: 4px;
    `
}