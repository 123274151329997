import calFontStyle from "./lib/calFontStyle"

const windowSize = {
    "mobile": "800px",
}

const font = {
    "extra_small": calFontStyle("normal", "0.75rem", "400", "normal", "-2%"),
    "extra_small_bold": calFontStyle("bold", "0.75rem", "400", "normal", "-2%"),
    "small": calFontStyle("normal", "1rem", "400", "normal", "-2%"),
    "small_bold": calFontStyle("bold", "1rem", "400", "normal", "-2%"),
    "medium": calFontStyle("normal", "1.25rem", "400", "normal", "-2%"),
    "medium_bold": calFontStyle("bold", "1.25rem", "400", "normal", "-2%"),
    "large": calFontStyle("normal", "1.5rem", "400", "normal", "-2%"),
    "large_bold": calFontStyle("bold", "1.5rem", "400", "normal", "-2%"),
    "extra_large": calFontStyle("normal", "2rem", "400", "normal", "-2%"),
    "extra_large_bold": calFontStyle("bold", "2rem", "400", "normal", "-2%"),
    "heading": calFontStyle("bold", "3rem", "400", "normal", "-2%"),
}

const color = {
    "white": "#FFFFFF",
    "grey": "#EFEFEF",
    "dark_grey": "#D9D9D9",
    "black": "#363342",
    "major": "#093191",
    "light_major": "#09319119",
    "hover": "#000062",
    "minor": "#F2F3F8",
    "pass": "#88D849",
    "caution": "#E95252",
    "placeholder": "#A5A4A8",
    "opacity": "#36334266",
    "line": "#C5CEE5"
}

const theme = {
    windowSize,
    font,
    color
}

export default theme