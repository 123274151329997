import { useCookies } from "react-cookie"

const useAuthCookie = () => {

    const cookieName = "FHRMDLSZNZL"
    const [cookie, setCookie, removeCookie] = useCookies([cookieName])

    const createCookie = (token) => {
        setCookie(cookieName, token, { "path": "/", "expires": new Date(Date.now() + 1000 * 3600 * 24) })
    }

    const deleteCookie = () => {
        removeCookie(cookieName, { "path": "/" })
    }

    return [cookie[cookieName], createCookie, deleteCookie]
}

export default useAuthCookie